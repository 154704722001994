import { openDB } from 'idb';

let db;
const dbName = 'ClearWaste';
const version = 1;
const stores = [
    'savedReports',
];

async function init() {
    if (!('indexedDB' in window)) {
        console.log('This browser doesn\'t support IndexedDB');
        return false;
    }

    if (db) {
        return db;
    }

    db = await openDB(dbName, version, {
        upgrade(database) {
            stores.forEach((storeName) => {
                if (!database.objectStoreNames.contains(storeName)) {
                    database.createObjectStore(storeName);
                }
            });
        },
    });

    return db;
}

async function add(storeName, value, key) {
    if (!db) {
        await this.init();
        if (!db) return;
    }
    const transaction = db.transaction(storeName, 'readwrite');
    const store = await transaction.objectStore(storeName);
    await store.put(value, key);
    await transaction.done;
}

async function get(storeName, key) {
    if (!db) {
        await this.init();
        if (!db) return false;
    }
    const item = await db.transaction(storeName).objectStore(storeName).get(key);
    return item;
}

async function getAll(storeName) {
    if (!db) {
        await this.init();
        if (!db) return false;
    }
    const items = await db.transaction(storeName).objectStore(storeName).getAllKeys();
    return items;
}

async function remove(storeName, key) {
    if (!db) {
        await this.init();
        if (!db) return;
    }
    const transaction = db.transaction(storeName, 'readwrite');
    const store = await transaction.objectStore(storeName);
    await store.delete(key);
    await transaction.done;
}

export default {
    add, get, getAll, remove, init,
};
