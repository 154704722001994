/**
 * Validate contact details pattern
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     */
    check(value) {
        const checks = [
            /((?:\+?[0-9]|\()[0-9.\s\-()/]{7,20})(?:\b)/gi, // phone
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi, // url
            /(facebook)/gi, // facebook
            /(face(\s)?(-)?book)/gi, // face book
            /(\bfb\b)/gi, // fb
        ];
        let fail = false;
        checks.forEach((regex) => {
            if (!fail) {
                fail = !!`${value}`.match(regex);
            }
        });
        return !fail;
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return validation.message ? vm.$t(validation.message) : vm.$t('error_messages.contact_details');
    },
};
