export const errorHandler = (app, err) => {
    if (err?.response?.status === 403 || (typeof window !== 'undefined' && !window.navigator.onLine)) return;

    if (app && app.$sentry) {
        app.$sentry.captureException(err);
    } else {
        console.error(err);
    }
};

/**
 * Wrap promises in error handling
 *
 * @param {array} promises
 * @param {object} app
 */
export default function promiseErrors(promises, app) {
    return Promise.all(
        promises.map((promise) => promise.catch((err) => errorHandler(app, err))),
    );
}
