/**
 * Throttle calls to a function
 *
 * @param {function} fn
 * @param {number} delay
 */
export default function throttle(fn, delay) {
    let canCall = true;
    return () => {
        if (canCall) {
            fn();
            canCall = false;
            setTimeout(() => {
                canCall = true;
            }, delay);
        }
    };
}
