let channels = [];

function enableNotificationListener($echo, store, user) {
    store.dispatch('notifications/init');
    channels.push(`users.${user.id}.notifications`);
    $echo.private(`users.${user.id}.notifications`).notification((notification) => {
        store.dispatch('notifications/addNotification', notification);
    });
}

async function enableVerificationListener($echo, store, user) {
    if (!user.email_verified_at) {
        channels.push(`users.${user.id}.verify`);
        $echo.private(`users.${user.id}.verify`).listen('App\\Events\\User\\AccountWasVerified', ({ data }) => {
            store.dispatch('user/update', { email_verified_at: data.verified_at });
        });
    }
}

async function enableDummyListener($echo) {
    $echo.channel('dummy').listen('App\\Events\\DummyBroadcast', (n) => {
        console.log(n);
    });
}

export function enableListeners($echo, store) {
    const { user } = store.state.auth;
    enableNotificationListener($echo, store, user);
    enableVerificationListener($echo, store, user);
    enableDummyListener($echo);
}

export async function disableListeners($echo, store) {
    channels.forEach((channel) => {
        $echo.leave(channel);
    });
    channels = [];
    store.commit('notifications/reset');
}
