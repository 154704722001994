
/**
 * fsdfsdfsd
 */
export default {
    name: 'SiteAvatar',
    props: {
        /**
         * The size of the avatar
         * `xs, sm, md, lg, xl`
         */
        size: {
            type: String,
            default: 'lg',
        },
        /**
         * The user object
         */
        user: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        initials() {
            if (this.user.first_name) {
                let name = this.user.first_name[0];

                if (this.user.first_name[1]) {
                    name += this.user.first_name[1];
                }
                return name.toUpperCase();
            }
            if (!this.user.name) {
                return '';
            }
            const initials = this.user.name.match(/\b\w/g) || [];
            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        sizeClasses() {
            switch (this.size) {
                case 'xs':
                    return 'h-4 w-4 text-xs';
                case 'sm':
                    return 'h-6 w-6 text-xs';
                case 'md':
                    return 'h-8 w-8';
                case 'lg':
                default:
                    return 'h-12 w-12 text-lg';
                case 'xl':
                    return 'h-16 w-16 text-xl';
            }
        },
    },
};

