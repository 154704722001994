function getAddressParts(components) {
    const location = {
        city: '',
        county: '',
        country: '',
    };
    components.forEach((component) => {
        component.types.forEach((type) => {
            switch (type) {
                case 'route':
                    location.road = component.long_name;
                    break;
                case 'postal_town':
                    location.city = component.long_name;
                    break;
                case 'locality':
                    if (!location.city) {
                        location.city = component.long_name;
                    }
                    break;
                case 'neighborhood':
                    if (!location.city) {
                        location.city = component.long_name;
                    }
                    break;
                case 'country':
                    if (component.long_name === 'Ireland') {
                        location.county = location.country;
                        location.country = component.long_name;
                    }
                    break;
                case 'administrative_area_level_1':
                    location.country = component.long_name;
                    break;
                case 'administrative_area_level_2':
                    if (!location.county) {
                        location.county = component.long_name;
                    }
                    break;
                case 'postal_code':
                    location.postcode = component.long_name;
                    break;
                default:
                    break;
            }
        });
        if (!location.city) {
            location.city = location.county;
        }
    });
    return location;
}

function getFormattedAddress(location, vm) {
    if (vm) {
        return vm.$t('location.near_to', { location: `${location.road}, ${location.city}, ${location.county}` });
    }
    let formatted = '';
    if (location.road) {
        formatted += `${location.road}, `;
    }
    formatted = `${formatted}${location.city}`;
    if (location.city !== location.county) {
        formatted = `${formatted}, ${location.county}`;
    }
    if (!formatted && location.country) {
        formatted = location.country;
    }
    return formatted;
}

/**
 * Parse a Google places object into a simple object
 *
 * @param {object} place - A Google places object
 * @param {object} vm - A Vue instance
 */
export default function locationParser(place = null, vm = null) {
    if (place) {
        const location = getAddressParts(place.address_components);
        location.formatted = getFormattedAddress(location, vm);
        location.lat = typeof place.geometry.location.lat === 'function' ? place.geometry.location.lat() : place.geometry.location.lat;
        location.lon = typeof place.geometry.location.lng === 'function' ? place.geometry.location.lng() : place.geometry.location.lng;
        return location;
    }
    return null;
}
