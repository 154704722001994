import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8baf022c&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=8baf022c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8baf022c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSiteHeader: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteHeader.vue').default,SiteToast: require('/opt/atlassian/pipelines/agent/build/components/SiteToast.vue').default,LayoutSiteFooter: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooter.vue').default,ReportableModal: require('/opt/atlassian/pipelines/agent/build/components/reportable/ReportableModal.vue').default,SiteConfirmation: require('/opt/atlassian/pipelines/agent/build/components/SiteConfirmation.vue').default})
