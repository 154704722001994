import { render, staticRenderFns } from "./UserItem.vue?vue&type=template&id=dca8de8a&scoped=true"
import script from "./UserItem.vue?vue&type=script&lang=js"
export * from "./UserItem.vue?vue&type=script&lang=js"
import style0 from "./UserItem.vue?vue&type=style&index=0&id=dca8de8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca8de8a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserSiteAvatar: require('/opt/atlassian/pipelines/agent/build/components/user/SiteAvatar.vue').default})
