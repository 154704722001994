import { errorHandler } from '../services/promiseErrors';

const maxAge = 600000; // 10 Minutes

export const state = () => ({
    data: [],
    fetched: null,
});

export const actions = {
    get({ commit, state }) {
        return new Promise((resolve) => {
            if (!state.fetched || Date.now() - maxAge > state.fetched) {
                this.$axios.$get(
                    this.$config.endpoints.locations.country,
                    {
                        params: {
                            name: ['england', 'scotland', 'wales', 'northern ireland', 'ireland'],
                        },
                    },
                ).catch((err) => {
                    errorHandler(this, err);
                    resolve();
                }).then((countries) => {
                    if (countries) {
                        commit('ADD_COUNTRIES', countries.countries.data);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_COUNTRIES(state, countries) {
        state.data = countries;
        state.fetched = Date.now();
    },
};
