import isLength from 'validator/lib/isLength';

/**
 * Validate a maximum number of characters
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     * @param {object} form
     * @param {object} validation
     */
    check(value, form, validation) {
        return value ? isLength(`${value}`, { max: validation.length }) : true;
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return vm.$t('error_messages.max_length', { num: validation.length });
    },
};
