
export default {
    name: 'SiteModal',
    props: {
        maxWidth: {
            type: String,
            default: 'sm',
        },
        /**
         * Show the close button?
         */
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
};
