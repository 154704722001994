import { render, staticRenderFns } from "./SiteNotifications.vue?vue&type=template&id=fefff370&scoped=true"
import script from "./SiteNotifications.vue?vue&type=script&lang=js"
export * from "./SiteNotifications.vue?vue&type=script&lang=js"
import style0 from "./SiteNotifications.vue?vue&type=style&index=0&id=fefff370&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fefff370",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotificationsSiteNotification: require('/opt/atlassian/pipelines/agent/build/components/notifications/SiteNotification.vue').default,FormSiteButton: require('/opt/atlassian/pipelines/agent/build/components/form/SiteButton.vue').default})
