
import { mapActions } from 'vuex';
import IconQuestionCircle from '~/components/icon/IconQuestionCircle.vue';
import IconExclamationTriangle from '~/components/icon/IconExclamationTriangle.vue';
import IconCheckCircle from '~/components/icon/IconCheckCircle.vue';

export default {
    name: 'SiteToast',
    props: {
        inline: {
            type: Boolean,
            default: true,
        },
        /**
         * Toast type [primary, link, error, warning, success]
         */
        type: {
            type: String,
            default: 'success',
            validator(value) {
                return ['primary', 'error', 'success', 'warning', 'info', 'gray'].indexOf(value) !== -1;
            },
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
    },
    computed: {
        returnIcon() {
            switch (this.type) {
                case 'primary':
                case 'info':
                case 'gray':
                default:
                    return IconQuestionCircle;
                case 'error':
                case 'warning':
                    return IconExclamationTriangle;
                case 'success':
                    return IconCheckCircle;
            }
        },
        returnTitle() {
            if (this.title) {
                return this.title;
            }

            switch (this.type) {
                case 'primary':
                case 'info':
                case 'gray':
                default:
                    return this.$t('common.notification');
                case 'error':
                    return this.$t('common.error_exclamation');
                case 'warning':
                    return this.$t('common.warning_exclamation');
                case 'success':
                    return this.$t('common.success_exclamation');
            }
        },
    },
    methods: mapActions(['clearFlashMessage']),
};
