
import { required } from '~/services/validators';
import formMixin from '~/services/form';

export default {
    name: 'PageHeader',
    mixins: [formMixin],
    props: {
        /**
         * Is the page using tailwindtypography prose class
         */
        prose: {
            type: Boolean,
            default: false,
        },
        /**
         * Label for the search field
         */
        searchLabel: {
            type: String,
            default: '',
        },
        /**
         * Should we show the search button?
         */
        showSearch: {
            type: Boolean,
            default: false,
        },
        /**
         * The page text
         */
        text: {
            type: String,
            default: '',
        },
        /**
         * The page title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Should the page header have a transparent background
         */
        transparentBackground: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            query: this.$route.params.keyword || '',
            searchOpen: false,
        };
    },
    methods: {
        search() {
            /**
             * Start the seach
             *
             * @type {object}
             */
            this.$emit('search', { keyword: this.query });
        },
        searchToggle() {
            if (this.searchOpen) {
                this.searchOpen = false;
                document.body.focus();
            } else {
                this.searchOpen = true;
                this.$refs.search.focus();
            }
        },
    },
    validations: {
        query: [required],
    },
};
