import { disableListeners, enableListeners } from '~/services/socketListeners';

let echo;

async function connect(context, inject) {
    if (echo) {
        return;
    }

    const Echo = await import('laravel-echo' /* webpackChunkName: 'laravel-echo' */);
    const io = await import('socket.io-client' /* webpackChunkName: 'socket.io-client' */);

    window.io = io.default;

    /* eslint no-underscore-dangle: 0 */
    /* eslint new-cap: 0 */
    echo = new Echo.default({
        auth: { headers: { Authorization: context.$auth.$storage._state['_token.local'] } },
        broadcaster: 'socket.io',
        host: `${context.$config.socket.proto}://${context.$config.socket.host}:${context.$config.socket.port}`,
        namespace: '',
        reconnectionAttempts: 5,
    });

    enableListeners(echo, context.store);

    inject('echo', echo);
    context.$echo = echo;
}

async function disconnect(context) {
    if (echo) {
        await disableListeners(echo, context.store);
        echo.disconnect();
        echo = null;
    }
}

export default async function setupEcho(context, inject) {
    const { store } = context;

    if (store.state.auth.loggedIn) {
        connect(context, inject);
    }

    store.subscribe((mutation) => {
        if (mutation.type === 'auth/SET' && mutation.payload.key === 'loggedIn') {
            if (mutation.payload.value) {
                connect(context, inject);
            } else {
                disconnect(context);
            }
        }
    });
}
