import Cookies from 'universal-cookie';

const cookies = process.server ? new Cookies() : null;

let hasStorage = true;

try {
    localStorage.setItem('a', 'a');
    localStorage.removeItem('a');
    hasStorage = true;
} catch (exception) {
    hasStorage = false;
}

/**
 * Get an item from local / session storage
 *
 * @param {string} key
 * @param {boolean} session - Should we use sessionStorage?
 */
export function getStorage(key, session = false) {
    if (hasStorage) {
        if (session) {
            return JSON.parse(sessionStorage.getItem(key));
        }
        return JSON.parse(localStorage.getItem(key));
    }

    let value = cookies ? cookies.get(key) : '';
    if (/^[{[]/.test(value)) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            // logError(`Parse JSON from cookie, key: ${key} message: ${e.message}`, 'JSON');
        }
    }

    return value;
}

/**
 * Store an item in local / session storage
 *
 * @param {string} key
 * @param {string|object|array} value
 * @param {boolean} session - Should we use sessionStorage?
 */
export function setStorage(key, value, session = false) {
    if (hasStorage) {
        if (session) {
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } else {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        return cookies ? cookies.set(key, value) : value;
    }
    return value;
}

/**
 * Remove an item from local / session storage
 *
 * @param {string} key
 * @param {boolean} session - Should we use sessionStorage?
 */
export function removeStorage(key, session = false) {
    if (hasStorage) {
        if (session) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }

    return cookies ? cookies.remove(key) : '';
}
