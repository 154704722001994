const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['business'] = require('../middleware/business.js')
middleware['business'] = middleware['business'].default || middleware['business']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['magicToken'] = require('../middleware/magicToken.js')
middleware['magicToken'] = middleware['magicToken'].default || middleware['magicToken']

middleware['moderator'] = require('../middleware/moderator.js')
middleware['moderator'] = middleware['moderator'].default || middleware['moderator']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['recruit'] = require('../middleware/recruit.js')
middleware['recruit'] = middleware['recruit'].default || middleware['recruit']

export default middleware
