export const actions = {
    update({ commit }, params) {
        commit('UPDATE', params);
    },
    /* eslint camelcase: 0 */
    setCredit({ commit }, { credit, normalized_credit }) {
        commit('UPDATE_ORGANISATION', { credit, normalized_credit });
    },
};
export const mutations = {
    UPDATE(state, updateParams = {}) {
        const user = { ...this.state.auth.user, ...updateParams };
        this.state.auth.user = user;
    },
    UPDATE_ORGANISATION(state, updateParams = {}) {
        const organisation = { ...this.state.auth.user.organisation, ...updateParams };
        this.state.auth.user.organisation = organisation;
    },
};
