import { setStorage } from './storage';

function redirectToLogin($router, mediation) {
    if (mediation !== 'silent' && $router.currentRoute.name !== 'user-login') {
        $router.replace({ name: 'user-login' });
    }
}

/**
 * Login using navigator.credentials
 *
 * @param {object} $auth - The Nuxt auth object
 * @param {object} $store - The Vuex store
 * @param {object} $router - The Vue router
 * @param {string} mediation - optional, silent
 * @param {boolean} redirect
 */
export async function login($auth, $store, $router, mediation = 'optional', redirect = true) {
    if ($store.state.auth.loggedIn) {
        return;
    }

    if (!navigator.credentials || (process.client && !window.PasswordCredential)) {
        if (redirect) {
            redirectToLogin($router, mediation);
        }
        return;
    }

    await navigator.credentials.get({
        password: true,
        mediation,
    }).then((cred) => {
        if (!cred) {
            redirectToLogin($router, mediation);
            return;
        }
        if (cred.type === 'password') {
            $auth.loginWith('local', {
                data: {
                    email: cred.id,
                    password: cred.password,
                },
            }).then(() => {
                setStorage('loginConverted', true);
            }).catch(({ response }) => {
                let message = response.data.errors;
                if (typeof response.data.errors === 'object') {
                    const keys = Object.keys(response.data.errors);
                    [message] = response.data.errors[keys[0]];
                }
                $store.dispatch('setFlashMessage', { message, type: 'error' });
            });
        }
    }).catch(() => {
        redirectToLogin($router, mediation);
    });
}

/**
 * Logout and prevent silently logging back in
 */
export function logout() {
    if (process.client && navigator.credentials) {
        if (navigator.credentials.preventSilentAccess) {
            navigator.credentials.preventSilentAccess();
        } else {
            // For backwards compatibility Chrome < 60
            navigator.credentials.requireUserMediation();
        }
    }
}

/**
 * Store suer credentials
 *
 * @param {string} id
 * @param {string} password
 */
export function store(id, password) {
    if (navigator.credentials && window.PasswordCredential) {
        const cred = new window.PasswordCredential({
            id,
            password,
        });
        navigator.credentials.store(cred);
    }
}
