
import formMixin from '~/services/form';
import { required } from '~/services/validators';

export default {
    name: 'ReportModal',
    mixins: [formMixin],
    props: {
        /**
         * The api endpoint to send the reportable too
         */
        endpoint: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            report: '',
            type: '',
            types: [
                { name: this.$t('reportable.abuse'), slug: 'abuse' },
                { name: this.$t('reportable.copyright'), slug: 'copyright' },
                { name: this.$t('reportable.spam'), slug: 'spam' },
                { name: this.$t('reportable.other'), slug: 'other' },
            ],
        };
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        submit() {
            this.$form.submit(this.endpoint, '$post', { report: this.report, type: this.type }).then(({ message }) => {
                this.$store.dispatch('setFlashMessage', { message });
                this.$store.dispatch('hideReportable');
            });
        },
    },
    validations: {
        report: [required],
        type: [required],
    },
};
