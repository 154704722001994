import matches from 'validator/lib/matches';

/**
 * Validate a pattern
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     * @param {object} form
     * @param {object} validation
     */
    check(value, form, validation) {
        if (validation.inverse) {
            return !matches(`${value}`, validation.pattern);
        }
        return matches(`${value}`, validation.pattern);
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return vm.$t(validation.message);
    },
};
