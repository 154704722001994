import { render, staticRenderFns } from "./SiteHeaderUserMenu.vue?vue&type=template&id=58e914ee&scoped=true"
import script from "./SiteHeaderUserMenu.vue?vue&type=script&lang=js"
export * from "./SiteHeaderUserMenu.vue?vue&type=script&lang=js"
import style0 from "./SiteHeaderUserMenu.vue?vue&type=style&index=0&id=58e914ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e914ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserSiteAvatar: require('/opt/atlassian/pipelines/agent/build/components/user/SiteAvatar.vue').default})
