export const BlogPreview = () => import('../../components/BlogPreview.vue' /* webpackChunkName: "components/blog-preview" */).then(c => wrapFunctional(c.default || c))
export const BottomSheet = () => import('../../components/BottomSheet.vue' /* webpackChunkName: "components/bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const DropDownMenu = () => import('../../components/DropDownMenu.vue' /* webpackChunkName: "components/drop-down-menu" */).then(c => wrapFunctional(c.default || c))
export const GridWrapper = () => import('../../components/GridWrapper.vue' /* webpackChunkName: "components/grid-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HomePageSection = () => import('../../components/HomePageSection.vue' /* webpackChunkName: "components/home-page-section" */).then(c => wrapFunctional(c.default || c))
export const HorizontalCard = () => import('../../components/HorizontalCard.vue' /* webpackChunkName: "components/horizontal-card" */).then(c => wrapFunctional(c.default || c))
export const InfiniteScroll = () => import('../../components/InfiniteScroll.vue' /* webpackChunkName: "components/infinite-scroll" */).then(c => wrapFunctional(c.default || c))
export const InfoBox = () => import('../../components/InfoBox.vue' /* webpackChunkName: "components/info-box" */).then(c => wrapFunctional(c.default || c))
export const InstallApp = () => import('../../components/InstallApp.vue' /* webpackChunkName: "components/install-app" */).then(c => wrapFunctional(c.default || c))
export const ListWrapper = () => import('../../components/ListWrapper.vue' /* webpackChunkName: "components/list-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PaginationPager = () => import('../../components/PaginationPager.vue' /* webpackChunkName: "components/pagination-pager" */).then(c => wrapFunctional(c.default || c))
export const Press = () => import('../../components/Press.vue' /* webpackChunkName: "components/press" */).then(c => wrapFunctional(c.default || c))
export const SendSuggestion = () => import('../../components/SendSuggestion.vue' /* webpackChunkName: "components/send-suggestion" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const SiteAlert = () => import('../../components/SiteAlert.vue' /* webpackChunkName: "components/site-alert" */).then(c => wrapFunctional(c.default || c))
export const SiteConfirmation = () => import('../../components/SiteConfirmation.vue' /* webpackChunkName: "components/site-confirmation" */).then(c => wrapFunctional(c.default || c))
export const SiteCouncil = () => import('../../components/SiteCouncil.vue' /* webpackChunkName: "components/site-council" */).then(c => wrapFunctional(c.default || c))
export const SiteEmpty = () => import('../../components/SiteEmpty.vue' /* webpackChunkName: "components/site-empty" */).then(c => wrapFunctional(c.default || c))
export const SiteList = () => import('../../components/SiteList.vue' /* webpackChunkName: "components/site-list" */).then(c => wrapFunctional(c.default || c))
export const SiteModal = () => import('../../components/SiteModal.vue' /* webpackChunkName: "components/site-modal" */).then(c => wrapFunctional(c.default || c))
export const SiteRating = () => import('../../components/SiteRating.vue' /* webpackChunkName: "components/site-rating" */).then(c => wrapFunctional(c.default || c))
export const SiteSteps = () => import('../../components/SiteSteps.vue' /* webpackChunkName: "components/site-steps" */).then(c => wrapFunctional(c.default || c))
export const SiteToast = () => import('../../components/SiteToast.vue' /* webpackChunkName: "components/site-toast" */).then(c => wrapFunctional(c.default || c))
export const StackedListItem = () => import('../../components/StackedListItem.vue' /* webpackChunkName: "components/stacked-list-item" */).then(c => wrapFunctional(c.default || c))
export const StackedListWrapper = () => import('../../components/StackedListWrapper.vue' /* webpackChunkName: "components/stacked-list-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../components/address/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressAdd = () => import('../../components/address/AddressAdd.vue' /* webpackChunkName: "components/address-add" */).then(c => wrapFunctional(c.default || c))
export const AddressEmpty = () => import('../../components/address/AddressEmpty.vue' /* webpackChunkName: "components/address-empty" */).then(c => wrapFunctional(c.default || c))
export const AddressPicker = () => import('../../components/address/AddressPicker.vue' /* webpackChunkName: "components/address-picker" */).then(c => wrapFunctional(c.default || c))
export const BusinessAutoQuoteBanner = () => import('../../components/business/AutoQuoteBanner.vue' /* webpackChunkName: "components/business-auto-quote-banner" */).then(c => wrapFunctional(c.default || c))
export const BusinessAutoQuote = () => import('../../components/business/BusinessAutoQuote.vue' /* webpackChunkName: "components/business-auto-quote" */).then(c => wrapFunctional(c.default || c))
export const BusinessBan = () => import('../../components/business/BusinessBan.vue' /* webpackChunkName: "components/business-ban" */).then(c => wrapFunctional(c.default || c))
export const BusinessEmployees = () => import('../../components/business/BusinessEmployees.vue' /* webpackChunkName: "components/business-employees" */).then(c => wrapFunctional(c.default || c))
export const BusinessEmployeesInvite = () => import('../../components/business/BusinessEmployeesInvite.vue' /* webpackChunkName: "components/business-employees-invite" */).then(c => wrapFunctional(c.default || c))
export const BusinessForm = () => import('../../components/business/BusinessForm.vue' /* webpackChunkName: "components/business-form" */).then(c => wrapFunctional(c.default || c))
export const BusinessHints = () => import('../../components/business/BusinessHints.vue' /* webpackChunkName: "components/business-hints" */).then(c => wrapFunctional(c.default || c))
export const BusinessHintsAlert = () => import('../../components/business/BusinessHintsAlert.vue' /* webpackChunkName: "components/business-hints-alert" */).then(c => wrapFunctional(c.default || c))
export const BusinessItem = () => import('../../components/business/BusinessItem.vue' /* webpackChunkName: "components/business-item" */).then(c => wrapFunctional(c.default || c))
export const BusinessQuotes = () => import('../../components/business/BusinessQuotes.vue' /* webpackChunkName: "components/business-quotes" */).then(c => wrapFunctional(c.default || c))
export const BusinessRejected = () => import('../../components/business/BusinessRejected.vue' /* webpackChunkName: "components/business-rejected" */).then(c => wrapFunctional(c.default || c))
export const BusinessReview = () => import('../../components/business/BusinessReview.vue' /* webpackChunkName: "components/business-review" */).then(c => wrapFunctional(c.default || c))
export const BusinessTextLink = () => import('../../components/business/BusinessTextLink.vue' /* webpackChunkName: "components/business-text-link" */).then(c => wrapFunctional(c.default || c))
export const CommentsSiteComments = () => import('../../components/comments/SiteComments.vue' /* webpackChunkName: "components/comments-site-comments" */).then(c => wrapFunctional(c.default || c))
export const CommentsSiteCommentsComment = () => import('../../components/comments/SiteCommentsComment.vue' /* webpackChunkName: "components/comments-site-comments-comment" */).then(c => wrapFunctional(c.default || c))
export const CommentsSiteCommentsForm = () => import('../../components/comments/SiteCommentsForm.vue' /* webpackChunkName: "components/comments-site-comments-form" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundBanner = () => import('../../components/crowdfund/CrowdfundBanner.vue' /* webpackChunkName: "components/crowdfund-banner" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundClearWasteStats = () => import('../../components/crowdfund/CrowdfundClearWasteStats.vue' /* webpackChunkName: "components/crowdfund-clear-waste-stats" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundFAQ = () => import('../../components/crowdfund/CrowdfundFAQ.vue' /* webpackChunkName: "components/crowdfund-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundFAQs = () => import('../../components/crowdfund/CrowdfundFAQs.vue' /* webpackChunkName: "components/crowdfund-f-a-qs" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundHeader = () => import('../../components/crowdfund/CrowdfundHeader.vue' /* webpackChunkName: "components/crowdfund-header" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundJourney = () => import('../../components/crowdfund/CrowdfundJourney.vue' /* webpackChunkName: "components/crowdfund-journey" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundMediaCampaign = () => import('../../components/crowdfund/CrowdfundMediaCampaign.vue' /* webpackChunkName: "components/crowdfund-media-campaign" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundPress = () => import('../../components/crowdfund/CrowdfundPress.vue' /* webpackChunkName: "components/crowdfund-press" */).then(c => wrapFunctional(c.default || c))
export const CrowdfundTestimonials = () => import('../../components/crowdfund/CrowdfundTestimonials.vue' /* webpackChunkName: "components/crowdfund-testimonials" */).then(c => wrapFunctional(c.default || c))
export const DashboardBusiness = () => import('../../components/dashboard/DashboardBusiness.vue' /* webpackChunkName: "components/dashboard-business" */).then(c => wrapFunctional(c.default || c))
export const DashboardBusinessJobs = () => import('../../components/dashboard/DashboardBusinessJobs.vue' /* webpackChunkName: "components/dashboard-business-jobs" */).then(c => wrapFunctional(c.default || c))
export const DashboardCTA = () => import('../../components/dashboard/DashboardCTA.vue' /* webpackChunkName: "components/dashboard-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const DashboardCouncil = () => import('../../components/dashboard/DashboardCouncil.vue' /* webpackChunkName: "components/dashboard-council" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvents = () => import('../../components/dashboard/DashboardEvents.vue' /* webpackChunkName: "components/dashboard-events" */).then(c => wrapFunctional(c.default || c))
export const DashboardGroups = () => import('../../components/dashboard/DashboardGroups.vue' /* webpackChunkName: "components/dashboard-groups" */).then(c => wrapFunctional(c.default || c))
export const DashboardHints = () => import('../../components/dashboard/DashboardHints.vue' /* webpackChunkName: "components/dashboard-hints" */).then(c => wrapFunctional(c.default || c))
export const DashboardJob = () => import('../../components/dashboard/DashboardJob.vue' /* webpackChunkName: "components/dashboard-job" */).then(c => wrapFunctional(c.default || c))
export const DashboardJobs = () => import('../../components/dashboard/DashboardJobs.vue' /* webpackChunkName: "components/dashboard-jobs" */).then(c => wrapFunctional(c.default || c))
export const DashboardPaceReviewer = () => import('../../components/dashboard/DashboardPaceReviewer.vue' /* webpackChunkName: "components/dashboard-pace-reviewer" */).then(c => wrapFunctional(c.default || c))
export const DashboardRecruit = () => import('../../components/dashboard/DashboardRecruit.vue' /* webpackChunkName: "components/dashboard-recruit" */).then(c => wrapFunctional(c.default || c))
export const DashboardReport = () => import('../../components/dashboard/DashboardReport.vue' /* webpackChunkName: "components/dashboard-report" */).then(c => wrapFunctional(c.default || c))
export const DashboardReports = () => import('../../components/dashboard/DashboardReports.vue' /* webpackChunkName: "components/dashboard-reports" */).then(c => wrapFunctional(c.default || c))
export const DashboardUser = () => import('../../components/dashboard/DashboardUser.vue' /* webpackChunkName: "components/dashboard-user" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserFeature = () => import('../../components/dashboard/DashboardUserFeature.vue' /* webpackChunkName: "components/dashboard-user-feature" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserFeatures = () => import('../../components/dashboard/DashboardUserFeatures.vue' /* webpackChunkName: "components/dashboard-user-features" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserNearbyReports = () => import('../../components/dashboard/DashboardUserNearbyReports.vue' /* webpackChunkName: "components/dashboard-user-nearby-reports" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserWasteWarrior = () => import('../../components/dashboard/DashboardUserWasteWarrior.vue' /* webpackChunkName: "components/dashboard-user-waste-warrior" */).then(c => wrapFunctional(c.default || c))
export const EventForm = () => import('../../components/event/EventForm.vue' /* webpackChunkName: "components/event-form" */).then(c => wrapFunctional(c.default || c))
export const EventHeader = () => import('../../components/event/EventHeader.vue' /* webpackChunkName: "components/event-header" */).then(c => wrapFunctional(c.default || c))
export const EventHost = () => import('../../components/event/EventHost.vue' /* webpackChunkName: "components/event-host" */).then(c => wrapFunctional(c.default || c))
export const EventItem = () => import('../../components/event/EventItem.vue' /* webpackChunkName: "components/event-item" */).then(c => wrapFunctional(c.default || c))
export const EvidenceElectronicSignature = () => import('../../components/evidence/ElectronicSignature.vue' /* webpackChunkName: "components/evidence-electronic-signature" */).then(c => wrapFunctional(c.default || c))
export const FlytippingLocation = () => import('../../components/flytipping/FlytippingLocation.vue' /* webpackChunkName: "components/flytipping-location" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReport = () => import('../../components/flytipping/Report.vue' /* webpackChunkName: "components/flytipping-report" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReportEmailVerify = () => import('../../components/flytipping/ReportEmailVerify.vue' /* webpackChunkName: "components/flytipping-report-email-verify" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReportHeader = () => import('../../components/flytipping/ReportHeader.vue' /* webpackChunkName: "components/flytipping-report-header" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReportOwnerMenu = () => import('../../components/flytipping/ReportOwnerMenu.vue' /* webpackChunkName: "components/flytipping-report-owner-menu" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReportStacked = () => import('../../components/flytipping/ReportStacked.vue' /* webpackChunkName: "components/flytipping-report-stacked" */).then(c => wrapFunctional(c.default || c))
export const FlytippingReportThanks = () => import('../../components/flytipping/ReportThanks.vue' /* webpackChunkName: "components/flytipping-report-thanks" */).then(c => wrapFunctional(c.default || c))
export const FlytippingSavedReports = () => import('../../components/flytipping/SavedReports.vue' /* webpackChunkName: "components/flytipping-saved-reports" */).then(c => wrapFunctional(c.default || c))
export const FormDatePicker = () => import('../../components/form/DatePicker.vue' /* webpackChunkName: "components/form-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FormFileUpload = () => import('../../components/form/FileUpload.vue' /* webpackChunkName: "components/form-file-upload" */).then(c => wrapFunctional(c.default || c))
export const FormFileUploadItem = () => import('../../components/form/FileUploadItem.vue' /* webpackChunkName: "components/form-file-upload-item" */).then(c => wrapFunctional(c.default || c))
export const FormButton = () => import('../../components/form/FormButton.vue' /* webpackChunkName: "components/form-button" */).then(c => wrapFunctional(c.default || c))
export const FormButtonInput = () => import('../../components/form/FormButtonInput.vue' /* webpackChunkName: "components/form-button-input" */).then(c => wrapFunctional(c.default || c))
export const FormButtonRadio = () => import('../../components/form/FormButtonRadio.vue' /* webpackChunkName: "components/form-button-radio" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../components/form/FormCheckbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormErrors = () => import('../../components/form/FormErrors.vue' /* webpackChunkName: "components/form-errors" */).then(c => wrapFunctional(c.default || c))
export const FormWrapper = () => import('../../components/form/FormWrapper.vue' /* webpackChunkName: "components/form-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FormInlineRecaptcha = () => import('../../components/form/InlineRecaptcha.vue' /* webpackChunkName: "components/form-inline-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const FormMultiStepEditForm = () => import('../../components/form/MultiStepEditForm.vue' /* webpackChunkName: "components/form-multi-step-edit-form" */).then(c => wrapFunctional(c.default || c))
export const FormMultiStepForm = () => import('../../components/form/MultiStepForm.vue' /* webpackChunkName: "components/form-multi-step-form" */).then(c => wrapFunctional(c.default || c))
export const FormSiteButton = () => import('../../components/form/SiteButton.vue' /* webpackChunkName: "components/form-site-button" */).then(c => wrapFunctional(c.default || c))
export const FormSiteButtonGroup = () => import('../../components/form/SiteButtonGroup.vue' /* webpackChunkName: "components/form-site-button-group" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInput = () => import('../../components/form/SiteInput.vue' /* webpackChunkName: "components/form-site-input" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInputCheckbox = () => import('../../components/form/SiteInputCheckbox.vue' /* webpackChunkName: "components/form-site-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInputCoverageArea = () => import('../../components/form/SiteInputCoverageArea.vue' /* webpackChunkName: "components/form-site-input-coverage-area" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInputDate = () => import('../../components/form/SiteInputDate.vue' /* webpackChunkName: "components/form-site-input-date" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInputGeocomplete = () => import('../../components/form/SiteInputGeocomplete.vue' /* webpackChunkName: "components/form-site-input-geocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormSiteInputMap = () => import('../../components/form/SiteInputMap.vue' /* webpackChunkName: "components/form-site-input-map" */).then(c => wrapFunctional(c.default || c))
export const FormSitePayment = () => import('../../components/form/SitePayment.vue' /* webpackChunkName: "components/form-site-payment" */).then(c => wrapFunctional(c.default || c))
export const GroupForm = () => import('../../components/group/GroupForm.vue' /* webpackChunkName: "components/group-form" */).then(c => wrapFunctional(c.default || c))
export const GroupHeader = () => import('../../components/group/GroupHeader.vue' /* webpackChunkName: "components/group-header" */).then(c => wrapFunctional(c.default || c))
export const GroupItem = () => import('../../components/group/GroupItem.vue' /* webpackChunkName: "components/group-item" */).then(c => wrapFunctional(c.default || c))
export const IconAddressCard = () => import('../../components/icon/IconAddressCard.vue' /* webpackChunkName: "components/icon-address-card" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft = () => import('../../components/icon/IconArrowLeft.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/icon/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUp = () => import('../../components/icon/IconArrowUp.vue' /* webpackChunkName: "components/icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconArrowsH = () => import('../../components/icon/IconArrowsH.vue' /* webpackChunkName: "components/icon-arrows-h" */).then(c => wrapFunctional(c.default || c))
export const IconAsap = () => import('../../components/icon/IconAsap.vue' /* webpackChunkName: "components/icon-asap" */).then(c => wrapFunctional(c.default || c))
export const IconAsbestos = () => import('../../components/icon/IconAsbestos.vue' /* webpackChunkName: "components/icon-asbestos" */).then(c => wrapFunctional(c.default || c))
export const IconAsterisk = () => import('../../components/icon/IconAsterisk.vue' /* webpackChunkName: "components/icon-asterisk" */).then(c => wrapFunctional(c.default || c))
export const IconBell = () => import('../../components/icon/IconBell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c))
export const IconBellExclamation = () => import('../../components/icon/IconBellExclamation.vue' /* webpackChunkName: "components/icon-bell-exclamation" */).then(c => wrapFunctional(c.default || c))
export const IconBlog = () => import('../../components/icon/IconBlog.vue' /* webpackChunkName: "components/icon-blog" */).then(c => wrapFunctional(c.default || c))
export const IconBuilding = () => import('../../components/icon/IconBuilding.vue' /* webpackChunkName: "components/icon-building" */).then(c => wrapFunctional(c.default || c))
export const IconCalendarAlt = () => import('../../components/icon/IconCalendarAlt.vue' /* webpackChunkName: "components/icon-calendar-alt" */).then(c => wrapFunctional(c.default || c))
export const IconCalendarSpecific = () => import('../../components/icon/IconCalendarSpecific.vue' /* webpackChunkName: "components/icon-calendar-specific" */).then(c => wrapFunctional(c.default || c))
export const IconCalendarWeekend = () => import('../../components/icon/IconCalendarWeekend.vue' /* webpackChunkName: "components/icon-calendar-weekend" */).then(c => wrapFunctional(c.default || c))
export const IconChart = () => import('../../components/icon/IconChart.vue' /* webpackChunkName: "components/icon-chart" */).then(c => wrapFunctional(c.default || c))
export const IconChartLine = () => import('../../components/icon/IconChartLine.vue' /* webpackChunkName: "components/icon-chart-line" */).then(c => wrapFunctional(c.default || c))
export const IconCheckCircle = () => import('../../components/icon/IconCheckCircle.vue' /* webpackChunkName: "components/icon-check-circle" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/icon/IconChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronLeft = () => import('../../components/icon/IconChevronLeft.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/icon/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconCircle = () => import('../../components/icon/IconCircle.vue' /* webpackChunkName: "components/icon-circle" */).then(c => wrapFunctional(c.default || c))
export const IconCity = () => import('../../components/icon/IconCity.vue' /* webpackChunkName: "components/icon-city" */).then(c => wrapFunctional(c.default || c))
export const IconClipboardCheck = () => import('../../components/icon/IconClipboardCheck.vue' /* webpackChunkName: "components/icon-clipboard-check" */).then(c => wrapFunctional(c.default || c))
export const IconClipboardList = () => import('../../components/icon/IconClipboardList.vue' /* webpackChunkName: "components/icon-clipboard-list" */).then(c => wrapFunctional(c.default || c))
export const IconClock = () => import('../../components/icon/IconClock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c))
export const IconCog = () => import('../../components/icon/IconCog.vue' /* webpackChunkName: "components/icon-cog" */).then(c => wrapFunctional(c.default || c))
export const IconCommentDots = () => import('../../components/icon/IconCommentDots.vue' /* webpackChunkName: "components/icon-comment-dots" */).then(c => wrapFunctional(c.default || c))
export const IconComments = () => import('../../components/icon/IconComments.vue' /* webpackChunkName: "components/icon-comments" */).then(c => wrapFunctional(c.default || c))
export const IconCreditCard = () => import('../../components/icon/IconCreditCard.vue' /* webpackChunkName: "components/icon-credit-card" */).then(c => wrapFunctional(c.default || c))
export const IconCrossHairs = () => import('../../components/icon/IconCrossHairs.vue' /* webpackChunkName: "components/icon-cross-hairs" */).then(c => wrapFunctional(c.default || c))
export const IconDaytime = () => import('../../components/icon/IconDaytime.vue' /* webpackChunkName: "components/icon-daytime" */).then(c => wrapFunctional(c.default || c))
export const IconDelete = () => import('../../components/icon/IconDelete.vue' /* webpackChunkName: "components/icon-delete" */).then(c => wrapFunctional(c.default || c))
export const IconDigging = () => import('../../components/icon/IconDigging.vue' /* webpackChunkName: "components/icon-digging" */).then(c => wrapFunctional(c.default || c))
export const IconEllipsisH = () => import('../../components/icon/IconEllipsisH.vue' /* webpackChunkName: "components/icon-ellipsis-h" */).then(c => wrapFunctional(c.default || c))
export const IconEllipsisV = () => import('../../components/icon/IconEllipsisV.vue' /* webpackChunkName: "components/icon-ellipsis-v" */).then(c => wrapFunctional(c.default || c))
export const IconEnvelope = () => import('../../components/icon/IconEnvelope.vue' /* webpackChunkName: "components/icon-envelope" */).then(c => wrapFunctional(c.default || c))
export const IconEnvelopeOpen = () => import('../../components/icon/IconEnvelopeOpen.vue' /* webpackChunkName: "components/icon-envelope-open" */).then(c => wrapFunctional(c.default || c))
export const IconEnvelopeThin = () => import('../../components/icon/IconEnvelopeThin.vue' /* webpackChunkName: "components/icon-envelope-thin" */).then(c => wrapFunctional(c.default || c))
export const IconEvening = () => import('../../components/icon/IconEvening.vue' /* webpackChunkName: "components/icon-evening" */).then(c => wrapFunctional(c.default || c))
export const IconExclamationTriangle = () => import('../../components/icon/IconExclamationTriangle.vue' /* webpackChunkName: "components/icon-exclamation-triangle" */).then(c => wrapFunctional(c.default || c))
export const IconEye = () => import('../../components/icon/IconEye.vue' /* webpackChunkName: "components/icon-eye" */).then(c => wrapFunctional(c.default || c))
export const IconEyeSlash = () => import('../../components/icon/IconEyeSlash.vue' /* webpackChunkName: "components/icon-eye-slash" */).then(c => wrapFunctional(c.default || c))
export const IconFacebook = () => import('../../components/icon/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconFacebookSquare = () => import('../../components/icon/IconFacebookSquare.vue' /* webpackChunkName: "components/icon-facebook-square" */).then(c => wrapFunctional(c.default || c))
export const IconFlytip = () => import('../../components/icon/IconFlytip.vue' /* webpackChunkName: "components/icon-flytip" */).then(c => wrapFunctional(c.default || c))
export const IconFlytipping = () => import('../../components/icon/IconFlytipping.vue' /* webpackChunkName: "components/icon-flytipping" */).then(c => wrapFunctional(c.default || c))
export const IconGlobe = () => import('../../components/icon/IconGlobe.vue' /* webpackChunkName: "components/icon-globe" */).then(c => wrapFunctional(c.default || c))
export const IconGlobeEurope = () => import('../../components/icon/IconGlobeEurope.vue' /* webpackChunkName: "components/icon-globe-europe" */).then(c => wrapFunctional(c.default || c))
export const IconGrabBag = () => import('../../components/icon/IconGrabBag.vue' /* webpackChunkName: "components/icon-grab-bag" */).then(c => wrapFunctional(c.default || c))
export const IconGrabLorry = () => import('../../components/icon/IconGrabLorry.vue' /* webpackChunkName: "components/icon-grab-lorry" */).then(c => wrapFunctional(c.default || c))
export const IconHardHat = () => import('../../components/icon/IconHardHat.vue' /* webpackChunkName: "components/icon-hard-hat" */).then(c => wrapFunctional(c.default || c))
export const IconHome = () => import('../../components/icon/IconHome.vue' /* webpackChunkName: "components/icon-home" */).then(c => wrapFunctional(c.default || c))
export const IconIdBadge = () => import('../../components/icon/IconIdBadge.vue' /* webpackChunkName: "components/icon-id-badge" */).then(c => wrapFunctional(c.default || c))
export const IconImages = () => import('../../components/icon/IconImages.vue' /* webpackChunkName: "components/icon-images" */).then(c => wrapFunctional(c.default || c))
export const IconInfoCircle = () => import('../../components/icon/IconInfoCircle.vue' /* webpackChunkName: "components/icon-info-circle" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../components/icon/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconKey = () => import('../../components/icon/IconKey.vue' /* webpackChunkName: "components/icon-key" */).then(c => wrapFunctional(c.default || c))
export const IconLargeGrab = () => import('../../components/icon/IconLargeGrab.vue' /* webpackChunkName: "components/icon-large-grab" */).then(c => wrapFunctional(c.default || c))
export const IconList = () => import('../../components/icon/IconList.vue' /* webpackChunkName: "components/icon-list" */).then(c => wrapFunctional(c.default || c))
export const IconLogo = () => import('../../components/icon/IconLogo.vue' /* webpackChunkName: "components/icon-logo" */).then(c => wrapFunctional(c.default || c))
export const IconMapMarkerAlt = () => import('../../components/icon/IconMapMarkerAlt.vue' /* webpackChunkName: "components/icon-map-marker-alt" */).then(c => wrapFunctional(c.default || c))
export const IconMobile = () => import('../../components/icon/IconMobile.vue' /* webpackChunkName: "components/icon-mobile" */).then(c => wrapFunctional(c.default || c))
export const IconNewspaper = () => import('../../components/icon/IconNewspaper.vue' /* webpackChunkName: "components/icon-newspaper" */).then(c => wrapFunctional(c.default || c))
export const IconOil = () => import('../../components/icon/IconOil.vue' /* webpackChunkName: "components/icon-oil" */).then(c => wrapFunctional(c.default || c))
export const IconPaperPlane = () => import('../../components/icon/IconPaperPlane.vue' /* webpackChunkName: "components/icon-paper-plane" */).then(c => wrapFunctional(c.default || c))
export const IconPencilAlt = () => import('../../components/icon/IconPencilAlt.vue' /* webpackChunkName: "components/icon-pencil-alt" */).then(c => wrapFunctional(c.default || c))
export const IconPhone = () => import('../../components/icon/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconPinterestSquare = () => import('../../components/icon/IconPinterestSquare.vue' /* webpackChunkName: "components/icon-pinterest-square" */).then(c => wrapFunctional(c.default || c))
export const IconPlasterboard = () => import('../../components/icon/IconPlasterboard.vue' /* webpackChunkName: "components/icon-plasterboard" */).then(c => wrapFunctional(c.default || c))
export const IconPlus = () => import('../../components/icon/IconPlus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconPlusCircle = () => import('../../components/icon/IconPlusCircle.vue' /* webpackChunkName: "components/icon-plus-circle" */).then(c => wrapFunctional(c.default || c))
export const IconPoundSign = () => import('../../components/icon/IconPoundSign.vue' /* webpackChunkName: "components/icon-pound-sign" */).then(c => wrapFunctional(c.default || c))
export const IconPoundSignThick = () => import('../../components/icon/IconPoundSignThick.vue' /* webpackChunkName: "components/icon-pound-sign-thick" */).then(c => wrapFunctional(c.default || c))
export const IconQuestionCircle = () => import('../../components/icon/IconQuestionCircle.vue' /* webpackChunkName: "components/icon-question-circle" */).then(c => wrapFunctional(c.default || c))
export const IconRecycle = () => import('../../components/icon/IconRecycle.vue' /* webpackChunkName: "components/icon-recycle" */).then(c => wrapFunctional(c.default || c))
export const IconRoad = () => import('../../components/icon/IconRoad.vue' /* webpackChunkName: "components/icon-road" */).then(c => wrapFunctional(c.default || c))
export const IconRollerSkip = () => import('../../components/icon/IconRollerSkip.vue' /* webpackChunkName: "components/icon-roller-skip" */).then(c => wrapFunctional(c.default || c))
export const IconRoute = () => import('../../components/icon/IconRoute.vue' /* webpackChunkName: "components/icon-route" */).then(c => wrapFunctional(c.default || c))
export const IconSearchThin = () => import('../../components/icon/IconSearchThin.vue' /* webpackChunkName: "components/icon-search-thin" */).then(c => wrapFunctional(c.default || c))
export const IconShareAlt = () => import('../../components/icon/IconShareAlt.vue' /* webpackChunkName: "components/icon-share-alt" */).then(c => wrapFunctional(c.default || c))
export const IconSkip = () => import('../../components/icon/IconSkip.vue' /* webpackChunkName: "components/icon-skip" */).then(c => wrapFunctional(c.default || c))
export const IconSmallGrab = () => import('../../components/icon/IconSmallGrab.vue' /* webpackChunkName: "components/icon-small-grab" */).then(c => wrapFunctional(c.default || c))
export const IconSmallSkip = () => import('../../components/icon/IconSmallSkip.vue' /* webpackChunkName: "components/icon-small-skip" */).then(c => wrapFunctional(c.default || c))
export const IconSoil = () => import('../../components/icon/IconSoil.vue' /* webpackChunkName: "components/icon-soil" */).then(c => wrapFunctional(c.default || c))
export const IconStar = () => import('../../components/icon/IconStar.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c))
export const IconTh = () => import('../../components/icon/IconTh.vue' /* webpackChunkName: "components/icon-th" */).then(c => wrapFunctional(c.default || c))
export const IconThumbsUp = () => import('../../components/icon/IconThumbsUp.vue' /* webpackChunkName: "components/icon-thumbs-up" */).then(c => wrapFunctional(c.default || c))
export const IconTimes = () => import('../../components/icon/IconTimes.vue' /* webpackChunkName: "components/icon-times" */).then(c => wrapFunctional(c.default || c))
export const IconTimesThin = () => import('../../components/icon/IconTimesThin.vue' /* webpackChunkName: "components/icon-times-thin" */).then(c => wrapFunctional(c.default || c))
export const IconTools = () => import('../../components/icon/IconTools.vue' /* webpackChunkName: "components/icon-tools" */).then(c => wrapFunctional(c.default || c))
export const IconTruck = () => import('../../components/icon/IconTruck.vue' /* webpackChunkName: "components/icon-truck" */).then(c => wrapFunctional(c.default || c))
export const IconTwitter = () => import('../../components/icon/IconTwitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconTwitterSquare = () => import('../../components/icon/IconTwitterSquare.vue' /* webpackChunkName: "components/icon-twitter-square" */).then(c => wrapFunctional(c.default || c))
export const IconTyre = () => import('../../components/icon/IconTyre.vue' /* webpackChunkName: "components/icon-tyre" */).then(c => wrapFunctional(c.default || c))
export const IconUser = () => import('../../components/icon/IconUser.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c))
export const IconUserShield = () => import('../../components/icon/IconUserShield.vue' /* webpackChunkName: "components/icon-user-shield" */).then(c => wrapFunctional(c.default || c))
export const IconUsers = () => import('../../components/icon/IconUsers.vue' /* webpackChunkName: "components/icon-users" */).then(c => wrapFunctional(c.default || c))
export const IconVideo = () => import('../../components/icon/IconVideo.vue' /* webpackChunkName: "components/icon-video" */).then(c => wrapFunctional(c.default || c))
export const IconWasteLarge = () => import('../../components/icon/IconWasteLarge.vue' /* webpackChunkName: "components/icon-waste-large" */).then(c => wrapFunctional(c.default || c))
export const IconWasteMassive = () => import('../../components/icon/IconWasteMassive.vue' /* webpackChunkName: "components/icon-waste-massive" */).then(c => wrapFunctional(c.default || c))
export const IconWasteMedium = () => import('../../components/icon/IconWasteMedium.vue' /* webpackChunkName: "components/icon-waste-medium" */).then(c => wrapFunctional(c.default || c))
export const IconWasteMini = () => import('../../components/icon/IconWasteMini.vue' /* webpackChunkName: "components/icon-waste-mini" */).then(c => wrapFunctional(c.default || c))
export const IconWasteSmall = () => import('../../components/icon/IconWasteSmall.vue' /* webpackChunkName: "components/icon-waste-small" */).then(c => wrapFunctional(c.default || c))
export const IconWasteXlarge = () => import('../../components/icon/IconWasteXlarge.vue' /* webpackChunkName: "components/icon-waste-xlarge" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../components/icon/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const Job = () => import('../../components/job/Job.vue' /* webpackChunkName: "components/job" */).then(c => wrapFunctional(c.default || c))
export const JobCollected = () => import('../../components/job/JobCollected.vue' /* webpackChunkName: "components/job-collected" */).then(c => wrapFunctional(c.default || c))
export const JobConfirmed = () => import('../../components/job/JobConfirmed.vue' /* webpackChunkName: "components/job-confirmed" */).then(c => wrapFunctional(c.default || c))
export const JobEmpty = () => import('../../components/job/JobEmpty.vue' /* webpackChunkName: "components/job-empty" */).then(c => wrapFunctional(c.default || c))
export const JobHeader = () => import('../../components/job/JobHeader.vue' /* webpackChunkName: "components/job-header" */).then(c => wrapFunctional(c.default || c))
export const JobMenu = () => import('../../components/job/JobMenu.vue' /* webpackChunkName: "components/job-menu" */).then(c => wrapFunctional(c.default || c))
export const JobMissedOut = () => import('../../components/job/JobMissedOut.vue' /* webpackChunkName: "components/job-missed-out" */).then(c => wrapFunctional(c.default || c))
export const JobNoQuotes = () => import('../../components/job/JobNoQuotes.vue' /* webpackChunkName: "components/job-no-quotes" */).then(c => wrapFunctional(c.default || c))
export const JobPayment = () => import('../../components/job/JobPayment.vue' /* webpackChunkName: "components/job-payment" */).then(c => wrapFunctional(c.default || c))
export const JobProblems = () => import('../../components/job/JobProblems.vue' /* webpackChunkName: "components/job-problems" */).then(c => wrapFunctional(c.default || c))
export const JobQuote = () => import('../../components/job/JobQuote.vue' /* webpackChunkName: "components/job-quote" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteAccepted = () => import('../../components/job/JobQuoteAccepted.vue' /* webpackChunkName: "components/job-quote-accepted" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteBookingRow = () => import('../../components/job/JobQuoteBookingRow.vue' /* webpackChunkName: "components/job-quote-booking-row" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteForm = () => import('../../components/job/JobQuoteForm.vue' /* webpackChunkName: "components/job-quote-form" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteFormHints = () => import('../../components/job/JobQuoteFormHints.vue' /* webpackChunkName: "components/job-quote-form-hints" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteFormWrapper = () => import('../../components/job/JobQuoteFormWrapper.vue' /* webpackChunkName: "components/job-quote-form-wrapper" */).then(c => wrapFunctional(c.default || c))
export const JobQuotes = () => import('../../components/job/JobQuotes.vue' /* webpackChunkName: "components/job-quotes" */).then(c => wrapFunctional(c.default || c))
export const JobQuotesBusinessRow = () => import('../../components/job/JobQuotesBusinessRow.vue' /* webpackChunkName: "components/job-quotes-business-row" */).then(c => wrapFunctional(c.default || c))
export const JobQuotesRow = () => import('../../components/job/JobQuotesRow.vue' /* webpackChunkName: "components/job-quotes-row" */).then(c => wrapFunctional(c.default || c))
export const JobRow = () => import('../../components/job/JobRow.vue' /* webpackChunkName: "components/job-row" */).then(c => wrapFunctional(c.default || c))
export const JobQuoteMenu = () => import('../../components/job/QuoteMenu.vue' /* webpackChunkName: "components/job-quote-menu" */).then(c => wrapFunctional(c.default || c))
export const JobWasteServices = () => import('../../components/job/WasteServices.vue' /* webpackChunkName: "components/job-waste-services" */).then(c => wrapFunctional(c.default || c))
export const JobWasteServicesItem = () => import('../../components/job/WasteServicesItem.vue' /* webpackChunkName: "components/job-waste-services-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutLandingPageHeader = () => import('../../components/layout/LandingPageHeader.vue' /* webpackChunkName: "components/layout-landing-page-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeader = () => import('../../components/layout/PageHeader.vue' /* webpackChunkName: "components/layout-page-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeaderWithImage = () => import('../../components/layout/PageHeaderWithImage.vue' /* webpackChunkName: "components/layout-page-header-with-image" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteFooter = () => import('../../components/layout/SiteFooter.vue' /* webpackChunkName: "components/layout-site-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteFooterMenu = () => import('../../components/layout/SiteFooterMenu.vue' /* webpackChunkName: "components/layout-site-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteFooterMobile = () => import('../../components/layout/SiteFooterMobile.vue' /* webpackChunkName: "components/layout-site-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteFooterSocial = () => import('../../components/layout/SiteFooterSocial.vue' /* webpackChunkName: "components/layout-site-footer-social" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteHeader = () => import('../../components/layout/SiteHeader.vue' /* webpackChunkName: "components/layout-site-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutSiteHeaderUserMenu = () => import('../../components/layout/SiteHeaderUserMenu.vue' /* webpackChunkName: "components/layout-site-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const LocationCard = () => import('../../components/location/LocationCard.vue' /* webpackChunkName: "components/location-card" */).then(c => wrapFunctional(c.default || c))
export const MapGoogleMap = () => import('../../components/map/GoogleMap.vue' /* webpackChunkName: "components/map-google-map" */).then(c => wrapFunctional(c.default || c))
export const MapInteractiveMap = () => import('../../components/map/InteractiveMap.vue' /* webpackChunkName: "components/map-interactive-map" */).then(c => wrapFunctional(c.default || c))
export const MapInteractiveMapFilters = () => import('../../components/map/InteractiveMapFilters.vue' /* webpackChunkName: "components/map-interactive-map-filters" */).then(c => wrapFunctional(c.default || c))
export const MapInteractiveMapReport = () => import('../../components/map/InteractiveMapReport.vue' /* webpackChunkName: "components/map-interactive-map-report" */).then(c => wrapFunctional(c.default || c))
export const MapStatic = () => import('../../components/map/MapStatic.vue' /* webpackChunkName: "components/map-static" */).then(c => wrapFunctional(c.default || c))
export const MediaImageCover = () => import('../../components/media/ImageCover.vue' /* webpackChunkName: "components/media-image-cover" */).then(c => wrapFunctional(c.default || c))
export const MediaImageGroup = () => import('../../components/media/ImageGroup.vue' /* webpackChunkName: "components/media-image-group" */).then(c => wrapFunctional(c.default || c))
export const MediaImageThumbnail = () => import('../../components/media/ImageThumbnail.vue' /* webpackChunkName: "components/media-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const MediaCarousel = () => import('../../components/media/MediaCarousel.vue' /* webpackChunkName: "components/media-carousel" */).then(c => wrapFunctional(c.default || c))
export const MediaViewer = () => import('../../components/media/MediaViewer.vue' /* webpackChunkName: "components/media-viewer" */).then(c => wrapFunctional(c.default || c))
export const MediaYouTubeEmbed = () => import('../../components/media/YouTubeEmbed.vue' /* webpackChunkName: "components/media-you-tube-embed" */).then(c => wrapFunctional(c.default || c))
export const MessagesUserList = () => import('../../components/messages/MessagesUserList.vue' /* webpackChunkName: "components/messages-user-list" */).then(c => wrapFunctional(c.default || c))
export const MessagesSendMessage = () => import('../../components/messages/SendMessage.vue' /* webpackChunkName: "components/messages-send-message" */).then(c => wrapFunctional(c.default || c))
export const NotificationsSiteNotification = () => import('../../components/notifications/SiteNotification.vue' /* webpackChunkName: "components/notifications-site-notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationsSiteNotifications = () => import('../../components/notifications/SiteNotifications.vue' /* webpackChunkName: "components/notifications-site-notifications" */).then(c => wrapFunctional(c.default || c))
export const PaceEvidenceDescriptionList = () => import('../../components/pace/EvidenceDescriptionList.vue' /* webpackChunkName: "components/pace-evidence-description-list" */).then(c => wrapFunctional(c.default || c))
export const ReportableMenu = () => import('../../components/reportable/ReportableMenu.vue' /* webpackChunkName: "components/reportable-menu" */).then(c => wrapFunctional(c.default || c))
export const ReportableModal = () => import('../../components/reportable/ReportableModal.vue' /* webpackChunkName: "components/reportable-modal" */).then(c => wrapFunctional(c.default || c))
export const ServicesBagSizes = () => import('../../components/services/BagSizes.vue' /* webpackChunkName: "components/services-bag-sizes" */).then(c => wrapFunctional(c.default || c))
export const ServicesInstantSkipQuote = () => import('../../components/services/InstantSkipQuote.vue' /* webpackChunkName: "components/services-instant-skip-quote" */).then(c => wrapFunctional(c.default || c))
export const ServicesLocationBusinesses = () => import('../../components/services/LocationBusinesses.vue' /* webpackChunkName: "components/services-location-businesses" */).then(c => wrapFunctional(c.default || c))
export const ServicesLocationJobs = () => import('../../components/services/LocationJobs.vue' /* webpackChunkName: "components/services-location-jobs" */).then(c => wrapFunctional(c.default || c))
export const ServicesLocationServices = () => import('../../components/services/LocationServices.vue' /* webpackChunkName: "components/services-location-services" */).then(c => wrapFunctional(c.default || c))
export const ServicesLocationStats = () => import('../../components/services/LocationStats.vue' /* webpackChunkName: "components/services-location-stats" */).then(c => wrapFunctional(c.default || c))
export const ServicesHeader = () => import('../../components/services/ServicesHeader.vue' /* webpackChunkName: "components/services-header" */).then(c => wrapFunctional(c.default || c))
export const ServicesUsp = () => import('../../components/services/ServicesUsp.vue' /* webpackChunkName: "components/services-usp" */).then(c => wrapFunctional(c.default || c))
export const ServicesSkipSizes = () => import('../../components/services/SkipSizes.vue' /* webpackChunkName: "components/services-skip-sizes" */).then(c => wrapFunctional(c.default || c))
export const ServicesVanSizes = () => import('../../components/services/VanSizes.vue' /* webpackChunkName: "components/services-van-sizes" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTable = () => import('../../components/table/SiteTable.vue' /* webpackChunkName: "components/table-site-table" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTableBody = () => import('../../components/table/SiteTableBody.vue' /* webpackChunkName: "components/table-site-table-body" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTableColumn = () => import('../../components/table/SiteTableColumn.vue' /* webpackChunkName: "components/table-site-table-column" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTableHeader = () => import('../../components/table/SiteTableHeader.vue' /* webpackChunkName: "components/table-site-table-header" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTableHeaderColumn = () => import('../../components/table/SiteTableHeaderColumn.vue' /* webpackChunkName: "components/table-site-table-header-column" */).then(c => wrapFunctional(c.default || c))
export const TableSiteTableRow = () => import('../../components/table/SiteTableRow.vue' /* webpackChunkName: "components/table-site-table-row" */).then(c => wrapFunctional(c.default || c))
export const UserAccountSetting = () => import('../../components/user/AccountSetting.vue' /* webpackChunkName: "components/user-account-setting" */).then(c => wrapFunctional(c.default || c))
export const UserEmailVerify = () => import('../../components/user/EmailVerify.vue' /* webpackChunkName: "components/user-email-verify" */).then(c => wrapFunctional(c.default || c))
export const UserJoinPrompt = () => import('../../components/user/JoinPrompt.vue' /* webpackChunkName: "components/user-join-prompt" */).then(c => wrapFunctional(c.default || c))
export const UserSiteAvatar = () => import('../../components/user/SiteAvatar.vue' /* webpackChunkName: "components/user-site-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/user/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const UserItem = () => import('../../components/user/UserItem.vue' /* webpackChunkName: "components/user-item" */).then(c => wrapFunctional(c.default || c))
export const UserLoginForm = () => import('../../components/user/UserLoginForm.vue' /* webpackChunkName: "components/user-login-form" */).then(c => wrapFunctional(c.default || c))
export const UserRegisterForm = () => import('../../components/user/UserRegisterForm.vue' /* webpackChunkName: "components/user-register-form" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalQuoteFormService = () => import('../../components/waste-removal/QuoteFormService.vue' /* webpackChunkName: "components/waste-removal-quote-form-service" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalServiceCard = () => import('../../components/waste-removal/ServiceCard.vue' /* webpackChunkName: "components/waste-removal-service-card" */).then(c => wrapFunctional(c.default || c))
export const BusinessApproveMenu = () => import('../../components/business/approve/BusinessApproveMenu.vue' /* webpackChunkName: "components/business-approve-menu" */).then(c => wrapFunctional(c.default || c))
export const BusinessApproveRow = () => import('../../components/business/approve/BusinessApproveRow.vue' /* webpackChunkName: "components/business-approve-row" */).then(c => wrapFunctional(c.default || c))
export const BusinessReject = () => import('../../components/business/approve/BusinessReject.vue' /* webpackChunkName: "components/business-reject" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormAutoQuoteForm = () => import('../../components/business/form/AutoQuoteForm.vue' /* webpackChunkName: "components/business-form-auto-quote-form" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormAutoQuoteFormType = () => import('../../components/business/form/AutoQuoteFormType.vue' /* webpackChunkName: "components/business-form-auto-quote-form-type" */).then(c => wrapFunctional(c.default || c))
export const BusinessDetailsForm = () => import('../../components/business/form/BusinessDetailsForm.vue' /* webpackChunkName: "components/business-details-form" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormCoverageAreaForm = () => import('../../components/business/form/CoverageAreaForm.vue' /* webpackChunkName: "components/business-form-coverage-area-form" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormLicenceForm = () => import('../../components/business/form/LicenceForm.vue' /* webpackChunkName: "components/business-form-licence-form" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormServicesForm = () => import('../../components/business/form/ServicesForm.vue' /* webpackChunkName: "components/business-form-services-form" */).then(c => wrapFunctional(c.default || c))
export const EvidenceFormDetailsForm = () => import('../../components/evidence/form/DetailsForm.vue' /* webpackChunkName: "components/evidence-form-details-form" */).then(c => wrapFunctional(c.default || c))
export const EvidenceFormLocationForm = () => import('../../components/evidence/form/LocationForm.vue' /* webpackChunkName: "components/evidence-form-location-form" */).then(c => wrapFunctional(c.default || c))
export const EvidenceFormStatementForm = () => import('../../components/evidence/form/StatementForm.vue' /* webpackChunkName: "components/evidence-form-statement-form" */).then(c => wrapFunctional(c.default || c))
export const EvidenceFormUploadsForm = () => import('../../components/evidence/form/UploadsForm.vue' /* webpackChunkName: "components/evidence-form-uploads-form" */).then(c => wrapFunctional(c.default || c))
export const FlytippingDetailsForm = () => import('../../components/flytipping/form/FlytippingDetailsForm.vue' /* webpackChunkName: "components/flytipping-details-form" */).then(c => wrapFunctional(c.default || c))
export const NotificationsSettings = () => import('../../components/notifications/settings/NotificationsSettings.vue' /* webpackChunkName: "components/notifications-settings" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalFormContactForm = () => import('../../components/waste-removal/form/ContactForm.vue' /* webpackChunkName: "components/waste-removal-form-contact-form" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalFormServiceForm = () => import('../../components/waste-removal/form/ServiceForm.vue' /* webpackChunkName: "components/waste-removal-form-service-form" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalFormSizeForm = () => import('../../components/waste-removal/form/SizeForm.vue' /* webpackChunkName: "components/waste-removal-form-size-form" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalFormTimeForm = () => import('../../components/waste-removal/form/TimeForm.vue' /* webpackChunkName: "components/waste-removal-form-time-form" */).then(c => wrapFunctional(c.default || c))
export const WasteRemovalDetailsForm = () => import('../../components/waste-removal/form/WasteRemovalDetailsForm.vue' /* webpackChunkName: "components/waste-removal-details-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
