
import { mapState } from 'vuex';
import { getStorage } from '~/services/storage';

export default {
    props: {
        /**
         * Should the header be fixed position?
         */
        fixed: {
            type: [Boolean, String],
            default: false,
        },
        /**
         * The current user object or Boolean (false)
         */
        user: {
            type: [Boolean, Object],
            default: false,
        },
    },
    data() {
        return {
            loginConverted: false,
            showNotifications: false,
            showUserMenu: false,
        };
    },
    computed: {
        ...mapState(['auth', 'fromRoute', 'isMobile', 'notifications']),
        navItems() {
            let nav;
            if (this.user && this.user.organisation_id) {
                nav = [
                    {
                        title: this.$t('jobs.title'),
                        to: { name: 'jobs' },
                    },
                    {
                        title: this.$t('quotes.title'),
                        to: { name: 'quotes' },
                    },
                    {
                        title: this.$t('bookings.title'),
                        to: { name: 'bookings' },
                    },
                ];
            } else {
                nav = [
                    {
                        title: this.$t('waste_removal.get_quotes'),
                        to: { name: 'waste-removal' },
                    },
                    {
                        title: this.$t('titles.services'),
                        to: { name: 'services' },
                    },
                    {
                        title: this.$t('flytipping.title'),
                        to: { name: 'flytipping' },
                    },
                ];
            }

            if (this.$config.showCrowdfund) {
                nav.push({
                    title: this.$t('titles.crowdfund'),
                    to: { name: 'crowdfund' },
                });
            }

            return nav;
        },
    },
    mounted() {
        this.loginConverted = getStorage('loginConverted');
    },
    methods: {
        goBack() {
            if (!this.fromRoute.name) {
                this.$router.push({ name: 'index' });
            } else {
                this.$router.back();
            }
        },
    },
};
