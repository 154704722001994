/**
 * Validate a minimum value
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     * @param {object} form
     * @param {object} validation
     */
    check(value, form, validation) {
        return value !== null ? value >= validation.value : true;
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return vm.$t('error_messages.min_value', { num: validation.value });
    },
};
