import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=1453aca1&scoped=true"
import script from "./SiteFooter.vue?vue&type=script&lang=js"
export * from "./SiteFooter.vue?vue&type=script&lang=js"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=1453aca1&prod&scoped=true&lang=css"
import style1 from "./SiteFooter.vue?vue&type=style&index=1&id=1453aca1&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1453aca1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSiteFooterMobile: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooterMobile.vue').default,LayoutSiteFooterSocial: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooterSocial.vue').default,LayoutSiteFooterMenu: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooterMenu.vue').default})
