import isLength from 'validator/lib/isLength';

/**
 * Validate a required value
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     * @param {object} form
     * @param {object} validation
     */
    check(value, form, validation) {
        if (form[validation.field].$model === validation.value
            || (Array.isArray(validation.value) && Array.isArray(form[validation.field].$model)
                && !validation.value.length && !form[validation.field].$model.length)) {
            return typeof value !== 'undefined' && value !== null ? isLength(`${value}`, 1) : false;
        }

        return true;
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     */
    message(vm, fieldName) {
        const fieldTranslation = vm.$t(`form_fields.${fieldName}`);
        if (/[aeioAEIO]/.test(fieldTranslation[0])) {
            return vm.$t('error_messages.requiredAn', { fieldName: vm.$t(`form_fields.${fieldName}`) });
        }
        return vm.$t('error_messages.required', { fieldName: vm.$t(`form_fields.${fieldName}`) });
    },
};
