
export default {
    name: 'SiteNotification',
    props: {
        /**
         * A Notification object
         */
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        fullName() {
            if (!this.notification.data.instance.first_name && this.notification.data.instance.name) {
                return this.notification.data.instance.name;
            }
            if (!this.notification.data.instance.last_name) {
                return this.notification.data.instance.first_name;
            }
            return `${this.notification.data.instance.first_name} ${this.notification.data.instance.last_name}`;
        },
    },
    methods: {
        async clear() {
            await this.$axios.$put(this.$config.endpoints.notificationsRead.replace('{id}', this.notification.id));
            this.$store.dispatch('notifications/readNotification', this.notification);

            if (this.notification.data.url.split('?')[0] === this.$route.path) {
                window.location.reload();
            }
        },
    },
};
