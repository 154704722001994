
export default {
    name: 'UserItem',
    props: {
        /**
         * The size of the avatar
         * `xs, sm, md, lg, xl`
         */
        size: {
            type: String,
            default: 'lg',
        },
        /**
         * Do we wanna show the username?
         */
        showUsername: {
            type: Boolean,
            default: true,
        },
        /**
         * Allow for long names to be ellipsised.
         */
        shortNames: {
            type: Boolean,
            default: true,
        },
        /**
         * A user object
         */
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isMod() {
            return this.user.role === 'admin' || this.user.role === 'moderator';
        },
        link() {
            const currentUser = this.$store.state.auth.user;
            if (currentUser && this.user === currentUser) {
                return { name: 'account' };
            }
            return { name: 'profile-user', params: { user: this.user.slug } };
        },
    },
};
