import { render, staticRenderFns } from "./SiteFooterMenu.vue?vue&type=template&id=1c67eee9"
import script from "./SiteFooterMenu.vue?vue&type=script&lang=js"
export * from "./SiteFooterMenu.vue?vue&type=script&lang=js"
import style0 from "./SiteFooterMenu.vue?vue&type=style&index=0&id=1c67eee9&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconAddressCard: require('/opt/atlassian/pipelines/agent/build/components/icon/IconAddressCard.vue').default,IconBuilding: require('/opt/atlassian/pipelines/agent/build/components/icon/IconBuilding.vue').default,IconChart: require('/opt/atlassian/pipelines/agent/build/components/icon/IconChart.vue').default,IconNewspaper: require('/opt/atlassian/pipelines/agent/build/components/icon/IconNewspaper.vue').default,IconQuestionCircle: require('/opt/atlassian/pipelines/agent/build/components/icon/IconQuestionCircle.vue').default,IconTruck: require('/opt/atlassian/pipelines/agent/build/components/icon/IconTruck.vue').default,IconClipboardList: require('/opt/atlassian/pipelines/agent/build/components/icon/IconClipboardList.vue').default,IconUsers: require('/opt/atlassian/pipelines/agent/build/components/icon/IconUsers.vue').default,IconCalendarAlt: require('/opt/atlassian/pipelines/agent/build/components/icon/IconCalendarAlt.vue').default})
