import { errorHandler } from '~/services/promiseErrors';

export default async function magicToken({
    app, $axios, $config, store, route, req,
}) {
    if (route.query.mtoken) {
        const url = $config.endpoints.magicToken.replace('{token}', route.query.mtoken);
        const options = { params: {} };
        if (process.server) {
            options.params.ip_address = req.headers['x-forwarded-for']?.split(',').pop() || req.connection.remoteAddress;
        }
        const response = await $axios.$get(url, options).catch(errorHandler);
        if (response && response.token) {
            app.$auth.onRedirect(() => route.path);
            if (store.state.auth.loggedIn) {
                await app.$auth.logout().catch(errorHandler);
            }
            await app.$auth.setUserToken(response.token);
        }
    }
}
