import { render, staticRenderFns } from "./SiteFooterMobile.vue?vue&type=template&id=b125c0c8&scoped=true"
import script from "./SiteFooterMobile.vue?vue&type=script&lang=js"
export * from "./SiteFooterMobile.vue?vue&type=script&lang=js"
import style0 from "./SiteFooterMobile.vue?vue&type=style&index=0&id=b125c0c8&prod&lang=css"
import style1 from "./SiteFooterMobile.vue?vue&type=style&index=1&id=b125c0c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b125c0c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconHome: require('/opt/atlassian/pipelines/agent/build/components/icon/IconHome.vue').default,IconEllipsisH: require('/opt/atlassian/pipelines/agent/build/components/icon/IconEllipsisH.vue').default,UserItem: require('/opt/atlassian/pipelines/agent/build/components/user/UserItem.vue').default,LayoutSiteFooterMenu: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooterMenu.vue').default,LayoutSiteFooterSocial: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteFooterSocial.vue').default})
