export async function toDataUrl(images) {
    const promises = [];

    images.forEach(async (image) => {
        promises.push(
            new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e.target.result);
                };
                image.name = image.data.name;
                reader.readAsDataURL(image.data);
            }),
        );
    });

    const dataUrls = await Promise.all(promises);

    const dataImages = [];
    images.forEach((image, index) => {
        dataImages.push({
            ...image,
            data: dataUrls[index],
        });
    });
    return dataImages;
}

export function fromDataUrl(images) {
    images.forEach((image) => {
        const byteString = atob(image.data.split(',')[1]);
        const mimeString = image.data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }

        image.data = new File([new Blob([ab], { type: mimeString })], image.name, { type: mimeString });
    });

    return images;
}
