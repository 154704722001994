/**
 * This file is currently used to store stats for the homepage, it will need refactoring to be used elsewhere
 */

import { errorHandler } from '../services/promiseErrors';

const maxAge = 600000; // 10 Minutes

export const state = () => ({
    stats: {},
    fetched: null,
});

export const actions = {
    get({ commit, state }) {
        return new Promise((resolve) => {
            if (!state.fetched || Date.now() - maxAge > state.fetched) {
                this.$axios.$get(
                    this.$config.endpoints.locations.county,
                    { params: { country: 'england', per_page: 1 } },
                ).catch((err) => {
                    errorHandler(this, err);
                    resolve();
                }).then((stats) => {
                    if (stats) {
                        commit('ADD_STATS', stats.stats);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_STATS(state, stats) {
        state.stats = stats;
        state.fetched = Date.now();
    },
};
