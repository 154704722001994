/**
 * Validate price pattern
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     */
    check(value) {
        return !`${value}`.match(/£[0-9.]*/gi);
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return validation.message ? vm.$t(validation.message) : vm.$t('error_messages.price');
    },
};
