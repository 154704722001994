/**
 * Validate a required truthy value
 */
export default {
    /**
     * Check the validation
     * @param {boolean} value
     */
    check(value) {
        return value;
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     */
    message(vm, fieldName) {
        const fieldTranslation = vm.$t(`form_fields.${fieldName}`);
        if (/[aeioAEIO]/.test(fieldTranslation[0])) {
            return vm.$t('error_messages.requiredAn', { fieldName: vm.$t(`form_fields.${fieldName}`) });
        }
        return vm.$t('error_messages.required', { fieldName: vm.$t(`form_fields.${fieldName}`) });
    },
};
