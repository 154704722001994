import { getStorage } from '~/services/storage';

export const state = () => ({
    count: 0,
    notifications: [],
});

export const actions = {
    async init({ commit, rootState }) {
        if (rootState.auth.loggedIn) {
            const { notifications } = await this.$axios.$get(this.$config.endpoints.notifications);
            const lastRead = getStorage('notificationsRead') || 0;
            notifications.data.forEach((notification) => {
                commit('addNotificationAtEnd', notification);
                const notificationDate = new Date(notification.created_at);
                if (notificationDate.getTime() > lastRead) {
                    commit('incrementCount');
                }
            });
        }
    },
    addNotifications({ commit }, notifications) {
        notifications.forEach((notification) => {
            commit('addNotificationAtEnd', notification);
        });
    },
    addNotification({ commit }, notification) {
        commit('addNotification', notification);
        commit('incrementCount');
    },
    readNotification({ commit }, notification) {
        commit('readNotification', notification);
        commit('decrementCount');
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    reset(state) {
        state.count = 0;
        state.initialLoad = true;
        state.notifications = [];
    },
    setCount(state, value) {
        state.count = value;
    },
    incrementCount(state) {
        state.count += 1;
    },
    decrementCount(state) {
        if (state.count) {
            state.count -= 1;
        }
    },
    addNotification(state, notification) {
        state.notifications.unshift(notification);
    },
    addNotificationAtEnd(state, notification) {
        state.notifications.push(notification);
    },
    readNotification(state, notification) {
        notification.read_at = new Date();
    },
};
