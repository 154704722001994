import matches from 'validator/lib/matches';

/**
 * Validate a vehicle registration pattern
 */
export default {
    /**
     * Check the validation
     * @param {string|number|null} value
     * @param {object} form
     * @param {object} validation
     */
    check(value, form, validation = null) {
        // vehicle reg must be between 0-10 characters; upper case letters, space and numbers only.
        const regex = /(^[A-Z0-9\s]{0,10}$)/g;
        if (validation && validation.inverse) {
            return !matches(`${value}`, regex);
        }
        return matches(`${value}`, regex);
    },
    /**
     * Return an error message
     * @param {object} vm
     * @param {string} fieldName
     * @param {object} validation
     */
    message(vm, fieldName, validation) {
        return vm.$t(validation.message);
    },
};
