export default ({
    app, $config, store,
}) => {
    app.router.beforeEach((to, from, next) => {
        store.commit('SET_FROM_ROUTE', from);
        store.dispatch('showNav');
        next();
    });

    app.router.afterEach((to) => {
        setTimeout(() => {
            store.commit('SET_LANDING', $config.fullwidthPages.indexOf(to.name));
        }, 0);
    });
};
