import axios from 'axios';
import { analyticsEvent } from '~/plugins/analytics';
import fileUploadService from '~/services/fileUpload';
import { reverseGeocode } from '~/services/geocode';
import locationParser from '~/services/locationParser';
import { fromDataUrl } from '~/services/savedReports/dataUrl';

async function processWhat3Words(report, config) {
    if (!report.what3words) {
        return report;
    }

    const words = report.what3words.replace('///', '');
    const { data } = await axios.get('https://api.what3words.com/v3/convert-to-coordinates', {
        params: {
            words,
            key: config.what3WordsKey,
        },
    });
    if (data) {
        report.latLng = `${data.coordinates.lat},${data.coordinates.lng}`;
        delete report.what3words;
    }

    return report;
}

async function geocode(report, store) {
    if (!report.latLng) {
        return report;
    }

    const [latitude, longitude] = report.latLng.split(',');
    const address = await reverseGeocode(latitude.trim(), longitude.trim(), store);
    if (address) {
        const location = locationParser(address);
        report = { ...report, ...location };
        delete report.latLng;
    }
    return report;
}

async function processImages(report, $axios, config, sentry) {
    let { images } = report;

    // We have already processed images
    if (Number.isInteger(images[0])) {
        return report;
    }

    images = fromDataUrl(report.images);
    await fileUploadService(images, $axios, config, sentry).then((uploadedImages) => {
        report.images = uploadedImages.map((image) => image.id);
    });

    return report;
}

export default async function persist(reportStorage, $axios, config, sentry, store, sentMsg) {
    const reports = await reportStorage.get();
    reports.forEach(async (report) => {
        report = await processImages(report, $axios, config, sentry);
        if (!Number.isInteger(report.images[report.images.length - 1])) {
            // If images aren't numeric something went wrong whilst uploading so bail
            return;
        }
        await reportStorage.update(report);

        report = await processWhat3Words(report, config);
        if (!report.latLng && !report.lat) {
            return;
        }
        await reportStorage.update(report);

        report = await geocode(report, store);
        if (!report.lat) {
            return;
        }
        await reportStorage.update(report);

        $axios.$post(config.endpoints.report, report).then(async () => {
            analyticsEvent('report', 'offline');
            await reportStorage.remove(report);
            store.dispatch('setFlashMessage', { message: sentMsg });
        });
    });
}
