export default function recruit({ store, redirect }) {
    // If the user is not authenticated
    if (!store.state.auth.loggedIn) {
        return redirect({ name: 'user-login' });
    }

    const { user } = store.state.auth;
    if (!user.isRecruit && !user.isAdminOrModerator) {
        return redirect({ name: 'dashboard' });
    }

    return true;
}
