
import { mapState } from 'vuex';
import IconCalendarAlt from '~/components/icon/IconCalendarAlt.vue';
import IconClipboardList from '~/components/icon/IconClipboardList.vue';
import IconFlytipping from '~/components/icon/IconFlytipping.vue';

export default {
    props: {
        /**
         * Object or Boolean (false) that represents the central action button
         */
        centralButton: {
            type: [Boolean, Object],
            required: true,
        },
        /**
         * The current user object or Boolean (false)
         */
        user: {
            type: [Boolean, Object],
            default: false,
        },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        ...mapState(['isCordova']),
        navItems() {
            if (this.user && this.user.organisation_id) {
                return [
                    {
                        title: this.$t('quotes.title'),
                        to: { name: 'quotes' },
                        icon: IconClipboardList,
                        iconClass: 'w-5',
                    },
                    {
                        title: this.$t('bookings.title'),
                        to: { name: 'bookings' },
                        icon: IconCalendarAlt,
                        iconClass: 'w-5',
                    },
                ];
            }
            return [
                {
                    title: this.$t('flytipping.title'),
                    to: { name: 'flytipping' },
                    icon: IconFlytipping,
                    iconClass: 'w-5',
                },
                {
                    title: this.$t('quotes.title'),
                    to: { name: 'waste-removal' },
                    icon: IconClipboardList,
                    iconClass: 'w-5',
                },
            ];
        },
    },
};
