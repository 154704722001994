import { errorHandler } from '../services/promiseErrors';

const maxAge = 600000; // 10 Minutes

export const state = () => ({
    data: [],
    fetched: null,
});

export const actions = {
    get({ commit, rootState, state }) {
        return new Promise((resolve) => {
            if (!state.fetched || Date.now() - maxAge > state.fetched) {
                this.$axios.$get(
                    this.$config.endpoints.report, { params: { per_page: rootState.isMobile ? 6 : 12 } },
                ).catch((err) => {
                    errorHandler(this, err);
                    resolve();
                }).then((reports) => {
                    if (reports) {
                        commit('ADD_REPORTS', reports.reports.data);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_REPORTS(state, reports) {
        state.data = reports;
        state.fetched = Date.now();
    },
};
