
export default {
    props: {
        /**
         * Is this a mobile device?
         */
        isMobile: {
            type: Boolean,
            default: false,
        },
        /**
         * The current user object or Boolean (false)
         */
        user: {
            type: [Boolean, Object],
            default: false,
        },
    },
};
