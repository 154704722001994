import galite from 'ga-lite';

export default ({ app, $config, store }) => {
    if (process.env.NODE_ENV !== 'production') return;

    // Disable beacons for Cordova as they are broken in WkWebView
    if (store.state.isCordova) {
        navigator.sendBeacon = null;
    }

    galite('create', $config.analytics, 'auto');

    app.router.afterEach(to => {
        galite('set', 'page', to.fullPath);
        galite('send', 'pageview');

        if (typeof gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_path: to.fullPath
            });
        }
    });
};

export function analyticsEvent(
    category = '',
    action = '',
    label = 'na',
    value = 1
) {
    if (process.env.NODE_ENV !== 'production') return;
    galite('send', 'event', category, action, label, value);
}
