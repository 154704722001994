
import { mapState } from 'vuex';
import { setStorage } from '~/services/storage';

export default {
    name: 'SiteNotifications',
    data() {
        return {
            loading: false,
            pagination: {
                canLoadMore: false,
                page: 1,
                perPage: 5,
            },
        };
    },
    computed: mapState('notifications', ['notifications']),
    mounted() {
        this.pagination.canLoadMore = this.notifications.length >= 5;
        const currentDate = new Date();
        setStorage('notificationsRead', currentDate.getTime());
        this.$store.commit('notifications/setCount', 0);
    },
    methods: {
        async loadMore() {
            this.loading = true;
            this.pagination.page += 1;
            const { notifications } = await this.$axios.$get(this.$config.endpoints.notifications, {
                params: {
                    page: this.pagination.page,
                },
            });
            this.pagination.canLoadMore = notifications.data.length === this.pagination.perPage;
            this.$store.dispatch('notifications/addNotifications', notifications.data);
            this.loading = false;
        },
    },
};
