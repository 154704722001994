
/* eslint vue/no-mutating-props: 0 */
import { required, requiredIf } from '~/services/validators';

export default {
    name: 'FormSiteInput',
    props: {
        /**
         * Autocomplete attribute - see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
         */
        autocomplete: {
            type: String,
            default: '',
        },
        /**
         * Does the field have a button at the end, the button should be passed in the slot
         */
        buttonAddon: {
            type: Boolean,
            default: false,
        },
        /**
         * Do we want to set this input as disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * A form object field
         */
        field: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
        /**
         * Hide the required indicator
         */
        hideRequiredIndicator: {
            type: Boolean,
            default: false,
        },
        /**
         * Should we highlight the field?
         */
        highlight: {
            type: Boolean,
            default: false,
        },
        /**
         * Is the form inline?
         */
        inline: {
            type: Boolean,
            default: false,
        },
        /**
         * Label for the input
         */
        label: {
            type: String,
            required: true,
        },
        /**
         * min field for number / date fields
         */
        min: {
            type: String,
            default: null,
        },
        /**
         * Name for the input
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * Array of options for select
         */
        options: {
            type: Array,
            default() {
                return [];
            },
        },
        /**
         * Attribute to use for option value
         */
        optionType: {
            type: String,
            default: 'slug',
        },
        /**
         * Placeholder for field
         */
        placeholder: {
            type: String,
            default: '',
        },
        /**
         * Number of rows, only applies when type is set to textarea
         */
        rows: {
            type: Number,
            default: 5,
        },
        /**
         * Input type
         * text, number, password, textarea etc
         */
        type: {
            type: String,
            default: 'text',
        },
    },
    computed: {
        required() {
            let isRequired = false;
            if (this.field.$params.length && !this.hideRequiredIndicator) {
                this.field.$params.forEach((param) => {
                    if (param === required || param === requiredIf || param.validator === required || param.validator === requiredIf) {
                        isRequired = true;
                    }
                });
            }
            return isRequired;
        },
        supportsDate() {
            if (typeof document === 'undefined') {
                return true;
            }
            const input = document.createElement('input');
            const value = 'a';
            input.setAttribute('type', 'date');
            input.setAttribute('value', value);
            return input.value !== value;
        },
    },
    methods: {
        focus() {
            this.$refs.field.focus();
        },
    },
};
