import { errorHandler } from '../services/promiseErrors';

const maxAge = 86400000; // 24 Hours

function getCategories(context, state, commit, type) {
    return new Promise((resolve) => {
        if (!state[type].fetched || Date.now() - maxAge > state[type].fetched) {
            context.$axios.$get(
                context.$config.endpoints.category, { params: { per_page: 25, type } },
            ).catch((err) => {
                errorHandler(context, err);
                resolve();
            }).then((categories) => {
                if (categories) {
                    commit('ADD_CATEGORIES', { categories: categories.categories.data, type });
                }
                resolve();
            });
        } else {
            resolve();
        }
    });
}

export const state = () => ({
    job: {
        data: [],
        fetched: null,
    },
    collection: {
        data: [],
        fetched: null,
    },
});

export const actions = {
    getJobCategories({ state, commit }) {
        return getCategories(this, state, commit, 'job');
    },
    getCollectionCategories({ commit, state }) {
        return getCategories(this, state, commit, 'collection');
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_CATEGORIES(state, { categories, type }) {
        state[type].data = categories;
        state[type].fetched = Date.now();
    },
};
