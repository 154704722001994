import { errorHandler } from '../services/promiseErrors';

const maxAge = 60000; // 1 Minute

export const state = () => ({
    data: [],
    fetched: null,
    user: null,
});

export const actions = {
    add({ commit }, address) {
        commit('ADD_ADDRESS', address);
    },
    delete({ commit }, id) {
        return this.$axios.$delete(`${this.$config.endpoints.address}/${id}`).then(() => {
            commit('REMOVE_ADDRESS', id);
        });
    },
    get({ commit, rootState, state }) {
        return new Promise((resolve) => {
            if (state.user && rootState.auth && rootState.auth.user.id !== state.user) {
                commit('ADD_ADDRESSES', { addresses: [] });
            }
            if (rootState.auth.loggedIn && (!state.fetched || Date.now() - maxAge > state.fetched)) {
                this.$axios.$get(
                    this.$config.endpoints.address,
                ).catch((err) => {
                    errorHandler(this, err);
                    resolve();
                }).then((response) => {
                    if (response) {
                        commit('ADD_ADDRESSES', { addresses: response.addresses, user: rootState.auth.user.id });
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
    setPrimary({ commit }, address) {
        commit('SET_PRIMARY', address);
        return this.$axios.put(`${this.$config.endpoints.address}/${address.id}`, {
            primary: true,
        });
    },
    update({ commit }, address) {
        commit('UPDATE_ADDRESS', address);
        return this.$axios.$put(`${this.$config.endpoints.address}/${address.id}`, {
            address_1: address.address_1,
            ...(address.address_2 && { address_2: address.address_2 }),
            house_name: address.house_name,
            city: address.city,
            ...(address.county && { county: address.county }),
            postcode: address.postcode,
        });
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_ADDRESS(state, address) {
        state.data.push(address);
    },
    ADD_ADDRESSES(state, { addresses, user }) {
        state.data = addresses;
        state.fetched = Date.now();
        if (user) {
            state.user = user;
        } else {
            state.user = null;
            state.fetched = null;
        }
    },
    REMOVE_ADDRESS(state, id) {
        state.data = state.data.filter((checkAddress) => checkAddress.id !== id);
    },
    SET_PRIMARY(state, address) {
        state.data.forEach((checkAddress) => {
            if (checkAddress.id === address.id) {
                checkAddress.primary = true;
            } else {
                checkAddress.primary = false;
            }
        });
    },
    UPDATE_ADDRESS(state, address) {
        state.data.forEach((checkAddress) => {
            if (checkAddress.id === address.id) {
                checkAddress.address_1 = address.address_1;
                checkAddress.address_2 = address.address_2;
                checkAddress.house_name = address.house_name;
                checkAddress.city = address.city;
                checkAddress.county = address.county;
                checkAddress.postcode = address.postcode;
            }
        });
    },
};
