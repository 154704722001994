import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=cc65e490&scoped=true"
import script from "./SiteHeader.vue?vue&type=script&lang=js"
export * from "./SiteHeader.vue?vue&type=script&lang=js"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&id=cc65e490&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc65e490",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconChevronLeft: require('/opt/atlassian/pipelines/agent/build/components/icon/IconChevronLeft.vue').default,IconBell: require('/opt/atlassian/pipelines/agent/build/components/icon/IconBell.vue').default,NotificationsSiteNotifications: require('/opt/atlassian/pipelines/agent/build/components/notifications/SiteNotifications.vue').default,UserSiteAvatar: require('/opt/atlassian/pipelines/agent/build/components/user/SiteAvatar.vue').default,LayoutSiteHeaderUserMenu: require('/opt/atlassian/pipelines/agent/build/components/layout/SiteHeaderUserMenu.vue').default,BottomSheet: require('/opt/atlassian/pipelines/agent/build/components/BottomSheet.vue').default,IconUser: require('/opt/atlassian/pipelines/agent/build/components/icon/IconUser.vue').default})
