
export default {
    name: 'FormSiteButton',
    props: {
        /**
         * What size should the button be ['xs', 'sm', 'lg', 'xl'] leave empty for default
         */
        size: {
            type: String,
            default: '',
            validator(value) {
                return ['xs', 'sm', 'lg', 'xl', ''].indexOf(value) !== -1;
            },
        },
        /**
         * Should the button be disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * A form object
         */
        form: {
            type: Object,
            default() {
                return {};
            },
        },
        /**
         * Is the button part of a button group?
         */
        group: {
            type: Boolean,
            default: false,
        },
        /**
         * Is the form inline?
         */
        inline: {
            type: Boolean,
            default: false,
        },
        /**
         * The top level tag to use ['button', 'a', 'nuxt-link']
         */
        tag: {
            type: String,
            default: 'button',
            validator(value) {
                return ['button', 'a', 'NuxtLink'].indexOf(value) !== -1;
            },
        },
        /**
         * Nuxt link route
         */
        to: {
            type: Object,
            default: null,
        },
        /**
         * Button type [primary, link, error, warning, success]
         */
        type: {
            type: String,
            default: null,
            validator(value) {
                return ['primary', 'secondary', 'link', 'info', 'error', 'error-outline', 'outline', 'success', 'warning'].indexOf(value) !== -1;
            },
        },
        /**
         * A tag target attribute: such as _blank
         */
        target: {
            type: String,
            default: '',
        },
    },
    computed: {
        classes() {
            return `${this.group ? '' : 'rounded'} ${this.sizeClasses} ${this.typeClasses}`;
        },
        sizeClasses() {
            let paddingY;
            if (this.inline) {
                paddingY = 'py-1';
            }

            switch (this.size) {
                case 'xs':
                    return `px-2 ${paddingY || 'py-1'} text-xs`;
                case 'sm':
                    return `px-3 ${paddingY || 'py-1'} text-sm`;
                case 'lg':
                    return `px-5 ${paddingY || 'py-2'} text-lg`;
                case 'xl':
                    return `px-6 ${paddingY || 'py-3'} text-xl`;
                default:
                    return `px-3 ${paddingY || 'py-2'} text-base`;
            }
        },
        typeClasses() {
            if (this.disabled) {
                return 'bg-white text-gray-light border-gray-lighter';
            }
            switch (this.type) {
                case 'primary':
                    return 'bg-primary hover:bg-primary-dark text-white hover:text-white border-primary';
                case 'info':
                    return 'bg-info-dark hover:bg-info-darker text-white hover:text-white border-info';
                case 'secondary':
                    return 'bg-white hover:bg-gray-lightest text-gray-dark hover:text-gray-dark border-gray-lighter';
                case 'link':
                    return 'border-transparent';
                case 'outline':
                    return 'bg-black hover:bg-gray-lightest hover:bg-opacity-50 hover:text-white text-white border-white';
                case 'error':
                    return 'bg-error hover:bg-error-darker text-white hover:text-white border-error';
                case 'error-outline':
                    return 'bg-transparent hover:bg-gray-lightest text-error hover:text-error-darker border-error hover:border-error-darker';
                case 'success':
                    return 'bg-success hover:bg-successHover text-white hover:text-white border-success';
                case 'warning':
                    return 'bg-warning hover:bg-warningHover text-white hover:text-white border-warning';
                default:
                    return 'bg-gray-lightest hover:bg-gray-lighter text-gray-dark border-gray-lighter';
            }
        },
    },
};
