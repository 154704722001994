import { errorHandler } from '~/services/promiseErrors';

const maxAge = 86400000; // 24 Hours

export const state = () => ({
    data: [],
    sizes: [],
    size_descriptions: [],
    fetched: null,
});

export const actions = {
    get({ state, commit }) {
        return new Promise((resolve) => {
            if (!state.fetched || Date.now() - maxAge > state.fetched) {
                this.$axios.$get(this.$config.endpoints.service).catch((err) => {
                    errorHandler(this, err);
                    resolve();
                }).then((services) => {
                    if (services) {
                        commit('ADD_SERVICES', services.services);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    ADD_SERVICES(state, services) {
        state.data = services.data;
        state.sizes = services.sizes;
        state.size_descriptions = services.size_descriptions;
        state.fetched = Date.now();
    },
};
