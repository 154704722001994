export default function business({ store, redirect }) {
    // If the user is not authenticated
    if (!store.state.auth.loggedIn) {
        return redirect({ name: 'user-login' });
    }

    const { user } = store.state.auth;
    if (!user.organisation_id && !['admin', 'moderator'].includes(user.role)) {
        return redirect({ name: 'dashboard' });
    }

    return true;
}
