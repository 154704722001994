import { render, staticRenderFns } from "./error.vue?vue&type=template&id=70a78e22"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconExclamationTriangle: require('/opt/atlassian/pipelines/agent/build/components/icon/IconExclamationTriangle.vue').default,IconArrowLeft: require('/opt/atlassian/pipelines/agent/build/components/icon/IconArrowLeft.vue').default})
