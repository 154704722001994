
import { mapState } from 'vuex';
import { getStorage } from '~/services/storage';
import IconList from '~/components/icon/IconList.vue';
import IconPlus from '~/components/icon/IconPlus.vue';
import IconUser from '~/components/icon/IconUser.vue';

export default {
    data() {
        return {
            excludedRoutes: ['flytipping-report-id-edit'],
            jobs: {
                route: 'jobs',
                link: 'jobs',
                icon: IconList,
                title: this.$t('jobs.title'),
            },
            links: {
                business: {
                    icon: IconPlus,
                    link: 'business-register',
                    title: this.$t('business.register'),
                },
                login: {
                    icon: IconUser,
                    link: 'user-login',
                    title: this.$t('auth.login'),
                },
                register: {
                    icon: IconUser,
                    link: 'user-register',
                    title: this.$t('auth.register'),
                },
            },
            loginConverted: false,
            pageLinks: [
                {
                    // keep this as position 0 for dashboard use
                    route: 'dashboard',
                    icon: IconPlus,
                    link: 'listings-choose',
                    title: this.$t('listings_add.title'),
                },
                {
                    route: 'flytipping',
                    icon: IconPlus,
                    link: 'flytipping-report',
                    title: this.$t('flytipping.report_flytipping'),
                },
                {
                    route: 'groups',
                    icon: IconPlus,
                    link: 'groups-create',
                    title: this.$t('groups.labels.create'),
                },
            ],
            showMenu: false,
            wasteRemoval: {
                link: 'waste-removal',
                icon: IconPlus,
                title: this.$t('waste_removal.get_quotes'),
            },
        };
    },
    computed: {
        ...mapState(['auth']),
        centralButton() {
            if (this.currentRoute === 'business' && this.auth.user && !this.auth.user.organisation) {
                return this.links.business;
            }
            if (this.currentRoute === 'dashboard' && this.auth.user && this.auth.user.organisation) {
                return this.jobs;
            }
            if (this.currentRoute === 'jobs-id' && this.auth.user && !this.auth.user.organisation) {
                return this.wasteRemoval;
            }
            if (this.currentRoute === 'dashboard' || this.currentRoute === 'index') {
                return this.pageLinks[0];
            }
            let link = false;
            this.pageLinks.forEach((pageLink) => {
                if (
                    this.currentRoute &&
                    this.currentRoute.indexOf(pageLink.route) !== -1 &&
                    this.currentRoute !== pageLink.link &&
                    this.excludedRoutes.indexOf(this.currentRoute) === -1
                ) {
                    link = pageLink;
                }
            });

            if (!link && this.currentRoute !== 'jobs' && this.auth.user && this.auth.user.organisation) {
                return this.jobs;
            }

            return link;
        },
        currentRoute() {
            return this.$route.name;
        },
    },
    mounted() {
        this.loginConverted = getStorage('loginConverted');
    },
};
