import axios from 'axios';
import config from '../config';

const hasCountry = (item) => {
    let country = false;
    item.address_components.forEach((addressComponent) => {
        if (addressComponent.types.indexOf('administrative_area_level_1') !== -1) {
            country = addressComponent;
        }
    });
    return country;
};

const appendCountry = (results, mostAccurateIndex) => {
    if (hasCountry(results[mostAccurateIndex])) {
        return results;
    }

    if (results.length > mostAccurateIndex + 1) {
        const countryComponent = hasCountry(results[mostAccurateIndex + 1]);
        if (countryComponent) {
            results[mostAccurateIndex].address_components.push(countryComponent);
        }
    }

    return results;
};

const findMostAccurateResult = (results) => {
    let mostAccurateIndex;
    if (results.length) {
        results.forEach((result, index) => {
            if (result.address_components[0].types[0] === 'street_number' && (!mostAccurateIndex || index < mostAccurateIndex)) {
                mostAccurateIndex = index;
            } else if (result.address_components[0].types[0] === 'route' && (!mostAccurateIndex || index < mostAccurateIndex)) {
                mostAccurateIndex = index;
            }
        });
        if (!mostAccurateIndex) {
            mostAccurateIndex = 0;
        }
        results = appendCountry(results, mostAccurateIndex);
        return results[mostAccurateIndex];
    }
    return false;
};

function makeRequest(params, resolve) {
    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
            ...params,
            key: config.googleMapsApiKey,
        },
    }).then(({ data }) => {
        resolve(findMostAccurateResult(data.results));
    }).catch((err) => {
        console.error(err);
    });
}

/**
 * Reverse geocode a lat / long
 *
 * @param {number} lat
 * @param {number} long
 */
export const reverseGeocode = (lat, long, store) => new Promise((resolve) => makeRequest({
    latlng: `${lat},${long}`,
    language: store.state.locale,
}, resolve));

/**
 * Reverse geocode an address
 *
 * @param {object} address
 */
export const geocode = (address) => new Promise((resolve) => makeRequest({ address }, resolve));
