
export default {
    name: 'SiteConfirmation',
    props: {
        callback: {
            type: Function,
            default() {
                return () => {};
            },
        },
        /**
         * A message to ask the user if they are sure
         */
        text: {
            type: String,
            required: true,
        },
        /**
         * Text for the confirm button
         */
        confirmButtonText: {
            type: String,
            default: null,
        },
        /**
         * Class for the confirm button
         */
        confirmButtonClass: {
            type: String,
            default: '',
        },
        /**
         * Type for the confirm button
         */
        confirmButtonType: {
            type: String,
            default: 'error',
            validator(value) {
                return ['primary', 'info', 'error', 'warning'].indexOf(value) !== -1;
            },
        },
        /**
         * Text for the reject button
         */
        rejectButtonText: {
            type: String,
            default: null,
        },
        /**
         * An optional title
         */
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        confirm() {
            /**
             * Confirm the action
             *
             * @type {null}
             */
            this.$emit('confirm');
            this.callback();
            this.hide();
        },
        hide() {
            this.$store.dispatch('hideConfirm');
            this.$emit('input', false);
        },
    },
};
