import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66ada6bb = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _a4d373c4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3051da32 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _622c3902 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _2ef99ef6 = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _745bb84e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2ad72ac0 = () => interopDefault(import('../pages/crowdfund.vue' /* webpackChunkName: "pages/crowdfund" */))
const _1e2dd3ca = () => interopDefault(import('../pages/customer-guarantee.vue' /* webpackChunkName: "pages/customer-guarantee" */))
const _aef8b776 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _932ccdc4 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _3dc1ef44 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _2d8dd67f = () => interopDefault(import('../pages/flytipping/index.vue' /* webpackChunkName: "pages/flytipping/index" */))
const _4b982419 = () => interopDefault(import('../pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _59016e9b = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _01f538ba = () => interopDefault(import('../pages/landowner-insurance.vue' /* webpackChunkName: "pages/landowner-insurance" */))
const _5a90add6 = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _687019de = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _3cfe0ea8 = () => interopDefault(import('../pages/messages/index/_user.vue' /* webpackChunkName: "pages/messages/index/_user" */))
const _64f37c96 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _ff66471e = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _68f07894 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _69df3439 = () => interopDefault(import('../pages/quotes.vue' /* webpackChunkName: "pages/quotes" */))
const _5f35bb12 = () => interopDefault(import('../pages/reportflytipper/index.vue' /* webpackChunkName: "pages/reportflytipper/index" */))
const _2fd458ba = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _30633fb5 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _b0760eac = () => interopDefault(import('../pages/waste-removal/index.vue' /* webpackChunkName: "pages/waste-removal/index" */))
const _8a569224 = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _797b1b86 = () => interopDefault(import('../pages/account/avatar.vue' /* webpackChunkName: "pages/account/avatar" */))
const _5c1af3e8 = () => interopDefault(import('../pages/account/email.vue' /* webpackChunkName: "pages/account/email" */))
const _5cdf7506 = () => interopDefault(import('../pages/account/mobile.vue' /* webpackChunkName: "pages/account/mobile" */))
const _7d0d856f = () => interopDefault(import('../pages/account/name.vue' /* webpackChunkName: "pages/account/name" */))
const _435cc334 = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _d8e51102 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _df42aace = () => interopDefault(import('../pages/account/payment-history.vue' /* webpackChunkName: "pages/account/payment-history" */))
const _44184fc0 = () => interopDefault(import('../pages/business/register.vue' /* webpackChunkName: "pages/business/register" */))
const _68fd77c6 = () => interopDefault(import('../pages/dashboard/council-reports.vue' /* webpackChunkName: "pages/dashboard/council-reports" */))
const _776583f2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _a641a9e8 = () => interopDefault(import('../pages/flytipping/map/index.vue' /* webpackChunkName: "pages/flytipping/map/index" */))
const _33a8c59a = () => interopDefault(import('../pages/flytipping/report/index.vue' /* webpackChunkName: "pages/flytipping/report/index" */))
const _18918f5a = () => interopDefault(import('../pages/groups/browse.vue' /* webpackChunkName: "pages/groups/browse" */))
const _4f20a9c5 = () => interopDefault(import('../pages/groups/create.vue' /* webpackChunkName: "pages/groups/create" */))
const _09e51f36 = () => interopDefault(import('../pages/listings/choose.vue' /* webpackChunkName: "pages/listings/choose" */))
const _00d635da = () => interopDefault(import('../pages/pace/dashboard.vue' /* webpackChunkName: "pages/pace/dashboard" */))
const _35b8e305 = () => interopDefault(import('../pages/pace/reports.vue' /* webpackChunkName: "pages/pace/reports" */))
const _5de9459d = () => interopDefault(import('../pages/services/grab-bags.vue' /* webpackChunkName: "pages/services/grab-bags" */))
const _03d068cb = () => interopDefault(import('../pages/services/man-and-van.vue' /* webpackChunkName: "pages/services/man-and-van" */))
const _fc567e5a = () => interopDefault(import('../pages/services/skip-hire.vue' /* webpackChunkName: "pages/services/skip-hire" */))
const _2b976b8c = () => interopDefault(import('../pages/services/waste-clearance.vue' /* webpackChunkName: "pages/services/waste-clearance" */))
const _8534b060 = () => interopDefault(import('../pages/user/forgotPassword.vue' /* webpackChunkName: "pages/user/forgotPassword" */))
const _136d12e7 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _605bc895 = () => interopDefault(import('../pages/user/register.vue' /* webpackChunkName: "pages/user/register" */))
const _0415010a = () => interopDefault(import('../pages/moderator/business/approve.vue' /* webpackChunkName: "pages/moderator/business/approve" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f9ad771 = () => interopDefault(import('../pages/moderator/business/register/_slug.vue' /* webpackChunkName: "pages/moderator/business/register/_slug" */))
const _25332b16 = () => interopDefault(import('../pages/flytipping/map/_type/index.vue' /* webpackChunkName: "pages/flytipping/map/_type/index" */))
const _77118385 = () => interopDefault(import('../pages/flytipping/report/_id/index.vue' /* webpackChunkName: "pages/flytipping/report/_id/index" */))
const _0013ad1e = () => interopDefault(import('../pages/flytipping/search/_keyword.vue' /* webpackChunkName: "pages/flytipping/search/_keyword" */))
const _16157b90 = () => interopDefault(import('../pages/groups/search/_keyword.vue' /* webpackChunkName: "pages/groups/search/_keyword" */))
const _7bf72166 = () => interopDefault(import('../pages/listings/add/_type.vue' /* webpackChunkName: "pages/listings/add/_type" */))
const _f44cc9b2 = () => interopDefault(import('../pages/flytipping/report/_id/edit.vue' /* webpackChunkName: "pages/flytipping/report/_id/edit" */))
const _4a7d1d14 = () => interopDefault(import('../pages/blog/_post.vue' /* webpackChunkName: "pages/blog/_post" */))
const _30e41800 = () => interopDefault(import('../pages/business/_slug/index.vue' /* webpackChunkName: "pages/business/_slug/index" */))
const _03d3afd5 = () => interopDefault(import('../pages/council/_council/index.vue' /* webpackChunkName: "pages/council/_council/index" */))
const _569fc5d9 = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _6253383d = () => interopDefault(import('../pages/flytipping/_country/index.vue' /* webpackChunkName: "pages/flytipping/_country/index" */))
const _6a0a2ed8 = () => interopDefault(import('../pages/groups/_slug/index.vue' /* webpackChunkName: "pages/groups/_slug/index" */))
const _7dfbc406 = () => interopDefault(import('../pages/jobs/_id/index.vue' /* webpackChunkName: "pages/jobs/_id/index" */))
const _0c185700 = () => interopDefault(import('../pages/pace/_id.vue' /* webpackChunkName: "pages/pace/_id" */))
const _1349ea72 = () => interopDefault(import('../pages/profile/_user.vue' /* webpackChunkName: "pages/profile/_user" */))
const _b7480908 = () => interopDefault(import('../pages/reset/_token.vue' /* webpackChunkName: "pages/reset/_token" */))
const _7ecbc399 = () => interopDefault(import('../pages/services/_country/index.vue' /* webpackChunkName: "pages/services/_country/index" */))
const _24ca813a = () => interopDefault(import('../pages/unsubscribe/_token.vue' /* webpackChunkName: "pages/unsubscribe/_token" */))
const _46f81efe = () => interopDefault(import('../pages/verify/_token.vue' /* webpackChunkName: "pages/verify/_token" */))
const _1e1faf4c = () => interopDefault(import('../pages/business/_slug/edit.vue' /* webpackChunkName: "pages/business/_slug/edit" */))
const _e5e0835a = () => interopDefault(import('../pages/events/_slug/edit.vue' /* webpackChunkName: "pages/events/_slug/edit" */))
const _2db7d838 = () => interopDefault(import('../pages/groups/_slug/edit.vue' /* webpackChunkName: "pages/groups/_slug/edit" */))
const _724c58ec = () => interopDefault(import('../pages/groups/_slug/events/index.vue' /* webpackChunkName: "pages/groups/_slug/events/index" */))
const _3a4e2adb = () => interopDefault(import('../pages/groups/_slug/members.vue' /* webpackChunkName: "pages/groups/_slug/members" */))
const _1538c13a = () => interopDefault(import('../pages/jobs/_id/add-photo.vue' /* webpackChunkName: "pages/jobs/_id/add-photo" */))
const _e35672f4 = () => interopDefault(import('../pages/jobs/_id/edit.vue' /* webpackChunkName: "pages/jobs/_id/edit" */))
const _1eb38e7c = () => interopDefault(import('../pages/jobs/_id/restore.vue' /* webpackChunkName: "pages/jobs/_id/restore" */))
const _3c14e874 = () => interopDefault(import('../pages/groups/_slug/events/create.vue' /* webpackChunkName: "pages/groups/_slug/events/create" */))
const _14b815ae = () => interopDefault(import('../pages/council/_council/register/_token.vue' /* webpackChunkName: "pages/council/_council/register/_token" */))
const _5f539751 = () => interopDefault(import('../pages/council/_council/resend/_token.vue' /* webpackChunkName: "pages/council/_council/resend/_token" */))
const _49afcdd2 = () => interopDefault(import('../pages/flytipping/_country/_county/index.vue' /* webpackChunkName: "pages/flytipping/_country/_county/index" */))
const _707d8873 = () => interopDefault(import('../pages/services/_country/_county/index.vue' /* webpackChunkName: "pages/services/_country/_county/index" */))
const _b1a0345c = () => interopDefault(import('../pages/flytipping/_country/_county/_city/index.vue' /* webpackChunkName: "pages/flytipping/_country/_county/_city/index" */))
const _01993fa4 = () => interopDefault(import('../pages/services/_country/_county/_city/index.vue' /* webpackChunkName: "pages/services/_country/_county/_city/index" */))
const _377b01c2 = () => interopDefault(import('../pages/flytipping/_country/_county/_city/_road/index.vue' /* webpackChunkName: "pages/flytipping/_country/_county/_city/_road/index" */))
const _078f71c4 = () => interopDefault(import('../pages/services/_country/_county/_city/_road/index.vue' /* webpackChunkName: "pages/services/_country/_county/_city/_road/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _66ada6bb,
    name: "about"
  }, {
    path: "/account",
    component: _a4d373c4,
    name: "account"
  }, {
    path: "/blog",
    component: _3051da32,
    name: "blog"
  }, {
    path: "/bookings",
    component: _622c3902,
    name: "bookings"
  }, {
    path: "/business",
    component: _2ef99ef6,
    name: "business"
  }, {
    path: "/contact",
    component: _745bb84e,
    name: "contact"
  }, {
    path: "/crowdfund",
    component: _2ad72ac0,
    name: "crowdfund"
  }, {
    path: "/customer-guarantee",
    component: _1e2dd3ca,
    name: "customer-guarantee"
  }, {
    path: "/dashboard",
    component: _aef8b776,
    name: "dashboard"
  }, {
    path: "/events",
    component: _932ccdc4,
    name: "events"
  }, {
    path: "/faq",
    component: _3dc1ef44,
    name: "faq"
  }, {
    path: "/flytipping",
    component: _2d8dd67f,
    name: "flytipping"
  }, {
    path: "/groups",
    component: _4b982419,
    name: "groups"
  }, {
    path: "/jobs",
    component: _59016e9b,
    name: "jobs"
  }, {
    path: "/landowner-insurance",
    component: _01f538ba,
    name: "landowner-insurance"
  }, {
    path: "/logos",
    component: _5a90add6,
    name: "logos"
  }, {
    path: "/messages",
    component: _687019de,
    name: "messages",
    children: [{
      path: ":user?",
      component: _3cfe0ea8,
      name: "messages-index-user"
    }]
  }, {
    path: "/notifications",
    component: _64f37c96,
    name: "notifications"
  }, {
    path: "/press",
    component: _ff66471e,
    name: "press"
  }, {
    path: "/privacy",
    component: _68f07894,
    name: "privacy"
  }, {
    path: "/quotes",
    component: _69df3439,
    name: "quotes"
  }, {
    path: "/reportflytipper",
    component: _5f35bb12,
    name: "reportflytipper"
  }, {
    path: "/services",
    component: _2fd458ba,
    name: "services"
  }, {
    path: "/terms",
    component: _30633fb5,
    name: "terms"
  }, {
    path: "/waste-removal",
    component: _b0760eac,
    name: "waste-removal"
  }, {
    path: "/account/addresses",
    component: _8a569224,
    name: "account-addresses"
  }, {
    path: "/account/avatar",
    component: _797b1b86,
    name: "account-avatar"
  }, {
    path: "/account/email",
    component: _5c1af3e8,
    name: "account-email"
  }, {
    path: "/account/mobile",
    component: _5cdf7506,
    name: "account-mobile"
  }, {
    path: "/account/name",
    component: _7d0d856f,
    name: "account-name"
  }, {
    path: "/account/notifications",
    component: _435cc334,
    name: "account-notifications"
  }, {
    path: "/account/password",
    component: _d8e51102,
    name: "account-password"
  }, {
    path: "/account/payment-history",
    component: _df42aace,
    name: "account-payment-history"
  }, {
    path: "/business/register",
    component: _44184fc0,
    name: "business-register"
  }, {
    path: "/dashboard/council-reports",
    component: _68fd77c6,
    name: "dashboard-council-reports"
  }, {
    path: "/dashboard/reports",
    component: _776583f2,
    name: "dashboard-reports"
  }, {
    path: "/flytipping/map",
    component: _a641a9e8,
    name: "flytipping-map"
  }, {
    path: "/flytipping/report",
    component: _33a8c59a,
    name: "flytipping-report"
  }, {
    path: "/groups/browse",
    component: _18918f5a,
    name: "groups-browse"
  }, {
    path: "/groups/create",
    component: _4f20a9c5,
    name: "groups-create"
  }, {
    path: "/listings/choose",
    component: _09e51f36,
    name: "listings-choose"
  }, {
    path: "/pace/dashboard",
    component: _00d635da,
    name: "pace-dashboard"
  }, {
    path: "/pace/reports",
    component: _35b8e305,
    name: "pace-reports"
  }, {
    path: "/services/grab-bags",
    component: _5de9459d,
    name: "services-grab-bags"
  }, {
    path: "/services/man-and-van",
    component: _03d068cb,
    name: "services-man-and-van"
  }, {
    path: "/services/skip-hire",
    component: _fc567e5a,
    name: "services-skip-hire"
  }, {
    path: "/services/waste-clearance",
    component: _2b976b8c,
    name: "services-waste-clearance"
  }, {
    path: "/user/forgotPassword",
    component: _8534b060,
    name: "user-forgotPassword"
  }, {
    path: "/user/login",
    component: _136d12e7,
    name: "user-login"
  }, {
    path: "/user/register",
    component: _605bc895,
    name: "user-register"
  }, {
    path: "/moderator/business/approve",
    component: _0415010a,
    name: "moderator-business-approve"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/moderator/business/register/:slug?",
    component: _1f9ad771,
    name: "moderator-business-register-slug"
  }, {
    path: "/flytipping/map/:type",
    component: _25332b16,
    name: "flytipping-map-type"
  }, {
    path: "/flytipping/report/:id",
    component: _77118385,
    name: "flytipping-report-id"
  }, {
    path: "/flytipping/search/:keyword?",
    component: _0013ad1e,
    name: "flytipping-search-keyword"
  }, {
    path: "/groups/search/:keyword?",
    component: _16157b90,
    name: "groups-search-keyword"
  }, {
    path: "/listings/add/:type?",
    component: _7bf72166,
    name: "listings-add-type"
  }, {
    path: "/flytipping/report/:id/edit",
    component: _f44cc9b2,
    name: "flytipping-report-id-edit"
  }, {
    path: "/blog/:post",
    component: _4a7d1d14,
    name: "blog-post"
  }, {
    path: "/business/:slug",
    component: _30e41800,
    name: "business-slug"
  }, {
    path: "/council/:council",
    component: _03d3afd5,
    name: "council-council"
  }, {
    path: "/events/:slug",
    component: _569fc5d9,
    name: "events-slug"
  }, {
    path: "/flytipping/:country",
    component: _6253383d,
    name: "flytipping-country"
  }, {
    path: "/groups/:slug",
    component: _6a0a2ed8,
    name: "groups-slug"
  }, {
    path: "/jobs/:id",
    component: _7dfbc406,
    name: "jobs-id"
  }, {
    path: "/pace/:id?",
    component: _0c185700,
    name: "pace-id"
  }, {
    path: "/profile/:user?",
    component: _1349ea72,
    name: "profile-user"
  }, {
    path: "/reset/:token?",
    component: _b7480908,
    name: "reset-token"
  }, {
    path: "/services/:country",
    component: _7ecbc399,
    name: "services-country"
  }, {
    path: "/unsubscribe/:token?",
    component: _24ca813a,
    name: "unsubscribe-token"
  }, {
    path: "/verify/:token?",
    component: _46f81efe,
    name: "verify-token"
  }, {
    path: "/business/:slug/edit",
    component: _1e1faf4c,
    name: "business-slug-edit"
  }, {
    path: "/events/:slug/edit",
    component: _e5e0835a,
    name: "events-slug-edit"
  }, {
    path: "/groups/:slug/edit",
    component: _2db7d838,
    name: "groups-slug-edit"
  }, {
    path: "/groups/:slug/events",
    component: _724c58ec,
    name: "groups-slug-events"
  }, {
    path: "/groups/:slug/members",
    component: _3a4e2adb,
    name: "groups-slug-members"
  }, {
    path: "/jobs/:id/add-photo",
    component: _1538c13a,
    name: "jobs-id-add-photo"
  }, {
    path: "/jobs/:id/edit",
    component: _e35672f4,
    name: "jobs-id-edit"
  }, {
    path: "/jobs/:id/restore",
    component: _1eb38e7c,
    name: "jobs-id-restore"
  }, {
    path: "/groups/:slug/events/create",
    component: _3c14e874,
    name: "groups-slug-events-create"
  }, {
    path: "/council/:council?/register/:token?",
    component: _14b815ae,
    name: "council-council-register-token"
  }, {
    path: "/council/:council?/resend/:token?",
    component: _5f539751,
    name: "council-council-resend-token"
  }, {
    path: "/flytipping/:country/:county",
    component: _49afcdd2,
    name: "flytipping-country-county"
  }, {
    path: "/services/:country/:county",
    component: _707d8873,
    name: "services-country-county"
  }, {
    path: "/flytipping/:country/:county/:city",
    component: _b1a0345c,
    name: "flytipping-country-county-city"
  }, {
    path: "/services/:country/:county/:city",
    component: _01993fa4,
    name: "services-country-county-city"
  }, {
    path: "/flytipping/:country/:county/:city/:road",
    component: _377b01c2,
    name: "flytipping-country-county-city-road"
  }, {
    path: "/services/:country/:county/:city/:road",
    component: _078f71c4,
    name: "services-country-county-city-road"
  }, {
    path: "/login",
    component: _136d12e7,
    name: "Login"
  }, {
    path: "/register",
    component: _605bc895,
    name: "Register"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
