export default function moderator({ error, store, redirect }) {
    // If the user is not authenticated
    if (!store.state.auth.loggedIn) {
        return redirect({ name: 'user-login' });
    }

    if (store.state.auth.user.role !== 'admin' && store.state.auth.user.role !== 'moderator') {
        error({ statusCode: 403, message: 'You do not have permission to view this page' });
    }

    return true;
}
