import createAuthRefreshInterceptor from 'axios-auth-refresh';

/* eslint import/no-extraneous-dependencies: 0 */
const https = process.server ? require('https') : undefined;

export default function axiosSetup({
    app,
    $axios,
    $config,
    isDev,
    redirect,
    store,
}) {
    /** @todo fudge to handle self signed cert in dev */
    if (isDev && process.server) {
        $axios.defaults.httpsAgent = new https.Agent({
            rejectUnauthorized: false,
        });
    }

    const refreshAuthLogic = (failedRequest) => $axios.post($config.endpoints.refresh, { skipAuthRefresh: true }).then((response) => {
        if (!response) {
            return Promise.reject();
        }
        const { data } = response;
        if (!data.token) {
            Promise.reject(new Error('Missing token on refresh'));
        }
        const token = `Bearer ${data.token}`;

        if (app.$auth) {
            /* eslint no-underscore-dangle: 0 */
            app.$auth.setToken(store.state.auth.strategy, token);
            app.$auth.strategy._setToken(token);
        } else {
            $axios.setToken(data.token, 'Bearer');
        }

        failedRequest.response.config.headers.Authorization = token;
        return Promise.resolve();
    });

    createAuthRefreshInterceptor($axios, refreshAuthLogic);

    $axios.setHeader('X-Version', $config.apiVersion);

    $axios.onError(async (err) => {
        const code = parseInt(err.response && err.response.status, 10);
        switch (code) {
            case 403:
                if (err.response.data.banned) {
                    if (store.state.auth.loggedIn) {
                        const oldRedirect = app.$auth.options.redirect;
                        app.$auth.options.redirect = false;
                        await app.$auth.logout();
                        app.$auth.options.redirect = oldRedirect;
                    }
                    redirect({ name: 'user-login' });
                    store.dispatch('setFlashMessage', { message: `You have been banned: ${err.response.data.comment}`, type: 'error' });
                }
                break;
            case 401:
                // Token expired errors are handled by the refresh handler ignore those
                if (typeof err.response !== 'undefined' && err.response?.data?.errors !== 'Token has expired'
                    && err.response?.data?.errors !== 'Token has expired and can no longer be refreshed') {
                    if (store.state.auth.loggedIn) {
                        await app.$auth.logout();
                    }
                    redirect({ name: 'user-login' });
                }
                break;
            default:
                if (isDev) {
                    if (typeof err.response !== 'undefined') {
                        console.log(code, err.response.data.errors, err.response.config.url);
                    }
                }
                break;
        }

        return Promise.reject(err);
    });
}
