import { getStorage, setStorage } from '~/services/storage';
import indexedDB from '~/services/indexedDB';
import { toDataUrl } from '~/services/savedReports/dataUrl';
import persist from '~/services/savedReports/persist';

const storageKey = 'savedReports';

export const reportStorage = {
    /**
     * Get all reports from local storage
     */
    async get() {
        if (indexedDB.init()) {
            const items = await indexedDB.get(storageKey, storageKey);
            return items || [];
        }
        return getStorage(storageKey) || [];
    },
    async remove(report) {
        let reports = await this.get();
        reports = reports.filter((checkReport) => checkReport.id !== report.id);
        if (indexedDB.init()) {
            indexedDB.add(storageKey, reports, storageKey);
        } else {
            setStorage(storageKey, reports);
        }
    },
    async save(report) {
        const localReport = {
            ...report,
            id: `_${Math.random().toString(36).substr(2, 9)}`,
        };

        if (localReport?.images?.length) {
            localReport.images = await toDataUrl(localReport.images);
        }

        const reports = await this.get();
        reports.push(localReport);
        if (indexedDB.init()) {
            indexedDB.add(storageKey, reports, storageKey);
        } else {
            setStorage(storageKey, reports);
        }
    },
    async update(updatedReport) {
        const reports = await this.get();
        reports.forEach((report, index) => {
            if (report.id === updatedReport.id) {
                reports[index] = updatedReport;
            }
        });

        if (indexedDB.init()) {
            indexedDB.add(storageKey, reports, storageKey);
        } else {
            setStorage(storageKey, reports);
        }
    },
};

export default function init($axios, config, sentry, store, sentMsg) {
    function online() {
        if (!window.navigator.onLine) return;

        // If we are still online after 30 seconds the connection is reasonably stable so start the upload
        setTimeout(() => {
            if (window.navigator.onLine) {
                persist(reportStorage, $axios, config, sentry, store, sentMsg);
            }
        }, 30000);
    }

    if (store.state.auth.loggedIn) {
        online();
        window.addEventListener('online', online);
    }

    store.subscribe((mutation) => {
        if (mutation.type === 'auth/SET' && mutation.payload.key === 'loggedIn') {
            if (mutation.payload.value) {
                online();
                window.addEventListener('online', online);
            } else {
                window.removeEventListener('online', online);
            }
        }
    });
}
