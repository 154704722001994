export default {
    analytics: 'UA-15260904-26',
    apiVersion: 1,
    appStore: {
        android: 'https://play.google.com/store/apps/details?id=com.taftech.clearwaste',
        apple: 'https://apps.apple.com/gb/app/clearwaste/id1462879562',
    },
    contact: {
        email: 'support@clearwaste.com',
        addressLine1: 'ClearWaste',
        addressLine2: '1634 Parkway',
        city: 'Whiteley',
        county: 'Hampshire',
        postcode: 'PO15 7AH',
        phoneNumber: '0370 490 9800',
    },
    domain: 'https://clearwaste.com',
    cdn_domain: 'https://cdn.clearwaste.com',
    environmentAgencyApi: 'https://environment.data.gov.uk/public-register/waste-carriers-brokers/registration/',
    iepaUrl: 'http://www.epa.ie/terminalfour/waste/waste-view.jsp?regno=',
    nieaUrl: 'https://public-registers.daera-ni.gov.uk/registration-of-carriers-ut',
    nrUrl: 'https://naturalresources.wales/permits-and-permissions/waste-carriers-brokers-and-dealers-public-register/?lang=en',
    sepaUrl: 'http://apps.sepa.org.uk/rocas/',
    stripePaymentUrl: 'https://dashboard.stripe.com/payments/',
    endpoints: {
        address: 'address',
        autoBidDefaults: 'auto-bid/defaults',
        bid: 'bid',
        bidInstant: 'bid/instant',
        blog: 'blog',
        booking: 'booking',
        category: 'category',
        collection: 'collection',
        contact: 'contact',
        councilRecruit: 'council/{council}/recruit',
        group: 'group',
        groupNearby: 'group-nearby',
        event: 'event',
        council: 'council',
        faq: 'faq',
        evidence: 'evidence',
        export: 'export',
        image: 'image',
        job: 'job',
        jobRestore: 'job/{id}/restore',
        jobSupport: 'job/{id}/support',
        jobInformationRequest: '/job/{job}/information-request',
        locations: {
            city: 'city',
            county: 'county',
            country: 'country',
            road: 'road',
        },
        login: 'login',
        logout: 'logout',
        magicToken: 'magic-token/{token}',
        mapCount: 'map/count',
        mapReports: 'map/reports',
        message: 'message',
        messageUsers: 'users-messages',
        notifications: 'notifications',
        notificationsCount: 'notificationsCount',
        notificationsRead: 'notifications/{id}/read',
        organisation: 'organisation',
        organisationBan: 'organisation/{slug}/ban',
        organisationDecline: 'organisation/{slug}/decline',
        organisationLink: 'organisation/link',
        organisationRegister: 'organisation/register',
        organisationToken: 'admin/organisation',
        organisationUserRegister: 'organisation/user/register',
        pages: {
            about: 'pages/about',
            guarantee: 'pages/guarantee',
            terms: 'pages/terms',
            privacy: 'pages/privacy',
        },
        pay: 'pay',
        payments: 'payments',
        paymentsReceipt: 'payments/{id}/resend-receipt',
        press: 'press',
        publicJob: 'public-job',
        refresh: 'refresh',
        register: 'register',
        report: 'report',
        reportNearby: 'report-nearby',
        reset: 'reset',
        resendEmailVerify: 'welcome',
        saveUserCard: 'user/settings/card',
        service: 'service',
        serviceStats: 'service/stats',
        settings: 'users/{slug}/settings',
        suggestion: 'suggestion',
        testimonial: 'testimonial',
        unsubscribe: 'unsubscribe',
        uploadImage: 'upload/image', // Image upload for non authed users
        user: 'user',
        verify: 'verify',
    },
    googleMapsApiKey: 'AIzaSyDL5RaVJkKs7BM2Acf7KTDFmCrpk6boePY',
    reCAPTCHA_site_key: '6LePjKwUAAAAADPExjmKAUdFUPlQD-Q0exZsiSvL',
    fullwidthPages: ['business', 'index', 'groups-slug', 'groups-slug-events', 'groups-slug-members', 'groups-slug-discussion', 'events-slug'],
    maxFileSize: 250000000, // N.B update error_messages.file_too_large translation if this changes
    maxNumFiles: 10, // N.B update error_messages.too_many_files translation if this changes
    socialLinks: {
        facebook: 'https://facebook.com/ClearWasteApp',
        instagram: 'https://www.instagram.com/clearwasteapp/',
        pinterest: 'https://www.pinterest.co.uk/clearwaste/',
        twitter: 'https://twitter.com/ClearWasteApp',
        youtube: 'https://www.youtube.com/channel/UCn2GBYPxPqdUhvsqt9TAB2w/',
    },
    supportedCountries: ['ie', 'gb'],
};
