
import { mapState } from 'vuex';
import { login } from '~/services/authenticator';
import savedReports from '~/services/savedReports';
import throttle from '~/services/throttle';

export default {
    data() {
        return {
            scrollPosition: process.client ? window.pageYOffset || document.documentElement.scrollTop : 0,
            showTopNav: 'top',
        };
    },
    head() {
        const path = this.$i18n.messages.en.metadata[this.$route.name];
        const head = {};
        if (path && path.title) {
            head.title = this.$t(`metadata.${this.$route.name}.title`);
            if (head.title.indexOf('ClearWaste') === -1) {
                head.title += ' - ClearWaste.com';
            }
        }
        if (path && path.description) {
            head.meta = [
                { hid: 'description', name: 'description', content: this.$t(`metadata.${this.$route.name}.description`) },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.$t(`metadata.${this.$route.name}.title`),
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t(`metadata.${this.$route.name}.description`),
                },
            ];
        }
        return head;
    },
    computed: mapState(['auth', 'confirm', 'flashMessage', 'isCordova', 'isLanding', 'isMobile', 'reportable', 'showNav']),
    created() {
        if (process.server && this.$device.isDesktop) {
            this.$store.commit('SET_IS_MOBILE', false);
        }
    },
    mounted() {
        savedReports(this.$axios, this.$config, this.$sentry, this.$store, this.$t('flytipping.reports_sent'));
        login(this.$auth, this.$store, this.$router, 'silent');
        this.setIsMobile();
        this.scrollPos()();
        this.initScrollWatcher();
        if (this.isCordova && window.universalLinks) {
            window.universalLinks.subscribe(null, (event) => {
                const url = event.url.split(/clearwaste.com/);
                this.$router.push(url[1]);
            });
        }
    },
    methods: {
        initScrollWatcher() {
            if (!this.isMobile && this.isMobile && !this.isLanding) {
                return;
            }
            window.addEventListener('scroll', this.scrollPos());
        },
        scrollPos() {
            return throttle(() => {
                const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                if (scrollPosition < this.scrollPosition) {
                    this.showTopNav = true;
                } else if (scrollPosition > 30 || (this.isLanding && scrollPosition > 0)) {
                    this.showTopNav = false;
                } else {
                    this.showTopNav = true;
                }
                this.scrollPosition = scrollPosition;
                setTimeout(() => {
                    const checkScroll = window.pageYOffset || document.documentElement.scrollTop;
                    if (checkScroll === 0) {
                        this.scrollPosition = 0;
                        this.showTopNav = 'top';
                    }
                }, 50);
            }, 50);
        },
        setIsMobile() {
            const watcher = window.matchMedia('(max-width: 767px)');
            this.$store.commit('SET_IS_MOBILE', watcher.matches);
            watcher.addListener((response) => {
                this.$store.commit('SET_IS_MOBILE', response.matches);
            });
        },
    },
};
