export const state = () => ({
    isLoading: false,
    isReady: false,
});

export const actions = {
    setLoading({ commit }) {
        commit('SET_LOADING');
    },
    setReady({ commit }) {
        commit('SET_READY');
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    SET_LOADING(state) {
        state.isLoading = true;
    },
    SET_READY(state) {
        state.isReady = true;
    },
};
