export default {
    methods: {
        externalLink(event) {
            if (!window.cordova) {
                return;
            }

            event.preventDefault();
            window.cordova.InAppBrowser.open(event.currentTarget.href, '_system');
        },
    },
};
