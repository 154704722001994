
import { mapState } from 'vuex';
import { logout } from '~/services/authenticator';

export default {
    props: {
        /**
         * Is this a mobile device?
         */
        isMobile: {
            type: Boolean,
            required: true,
        },
        /**
         * The total number of notifications
         */
        notificationCount: {
            type: Number,
            default: 0,
        },
    },
    computed: mapState(['auth']),
    methods: {
        logout() {
            this.$auth.logout().then(() => {
                logout();
                this.$router.replace({ name: 'index' });
            });
        },
    },
};
