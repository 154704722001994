/* eslint no-underscore-dangle: 0 */
let _cachedVue = null;
function getVue(rootVm) {
    if (_cachedVue) return _cachedVue;
    let Vue = rootVm.constructor;
    while (Vue.super) Vue = Vue.super;
    _cachedVue = Vue;
    return Vue;
}

let _cachedComponent = null;
const getComponent = (Vue) => {
    if (_cachedComponent) {
        return _cachedComponent;
    }
    const VBase = Vue.extend({});

    _cachedComponent = { VBase };
    return _cachedComponent;
};

/**
 * Validate a specific field
 *
 * @param {object} form
 * @param {object} field
 * @param {object} vm
 */
const validateField = (form, field, vm) => {
    if (!form.$submitted || field.$disabled) {
        return false;
    }
    field.$hasError = null;
    field.$errorMessage = '';
    let hasError = false;
    field.$params.forEach((validation) => {
        if (!hasError) {
            if (validation.validator) {
                hasError = !validation.validator.check(field.$model, form, validation);
                if (hasError) {
                    field.$errorMessage = vm.$t(validation.message) || validation.validator.message(vm, field.name, validation);
                }
            } else {
                hasError = !validation.check(field.$model, form, {});
                if (hasError) {
                    field.$errorMessage = validation.message(vm, field.name, {});
                }
            }
        }
    });
    field.$hasError = hasError;
    field.$error = field.$hasError;
    return field.$hasError;
};

/**
 * Check for errors on fields and update form.$hasErrors property
 *
 * @param {object} form
 * @param {array} fields
 */
const checkForFormErrors = (form, fields = form.fieldKeys) => {
    let formErrors = false;
    fields.forEach((fieldKey) => {
        if (form[fieldKey].$hasError) {
            formErrors = true;
        }
    });
    form.$hasError = formErrors;
};

/**
 * Validate all fields
 *
 * @param {object} form
 * @param {object} vm
 * @param {boolean} skipSubmittedCheck
 */
const validate = (form, vm, skipSubmittedCheck = false) => {
    form.$submitted = skipSubmittedCheck;
    if (!form.$submitted) {
        return false;
    }
    form.fieldKeys.forEach((fieldKey) => {
        validateField(form, form[fieldKey], vm);
    });
    checkForFormErrors(form);
    return !form.$hasError;
};

/**
 * Validate a subset of fields
 *
 * @param {object} form
 * @param {object} vm
 * @param {array} fields
 */
const validateFields = (form, vm, fields) => {
    form.$submitted = true;
    fields.forEach((fieldKey) => {
        validateField(form, form[fieldKey], vm);
    });
    checkForFormErrors(form, fields);
    return !form.$hasError;
};

/**
 * Setup props on a field
 *
 * @param {object} form
 * @param {object} field
 * @param {object} validations
 * @param {object} vm
 */
const setFieldProps = (form, field, validations, vm) => {
    const Vue = getVue(vm);
    if (form[field]) {
        form[field].$params = validations[field];
        return;
    }
    Vue.set(form, field, {
        $disabled: false,
        $errorMessage: null,
        _model: typeof vm[field] !== 'undefined' ? vm[field] : '',
        name: field,
        $hasError: null,
        $params: validations[field],
    });
    Object.defineProperty(form[field], '$model', {
        get() {
            return this._model;
        },
        set(value = vm[field]) {
            this._model = value;
            vm[field] = value;
            form.$errorMessage = null;
            if (validateField(form, form[field], vm)) {
                if (form.$submitted) {
                    checkForFormErrors(form);
                }
            } else {
                checkForFormErrors(form);
            }
        },
    });
};

/**
 * Add server errors onto each form field
 *
 * @param {object} form
 * @param {object} errors
 */
const addServerErrors = (form, errors) => {
    Object.keys(errors).forEach((field) => {
        if (form[field]) {
            form[field].$hasError = true;
            /* eslint prefer-destructuring: 0 */
            form[field].$errorMessage = errors[field][0];
        } else if (Object.keys(errors).length) {
            const errorKeys = Object.keys(errors);
            form.$errorMessage = errors[errorKeys][0];
        }
    });
    form.$hasError = true;
};

/**
 * Add new validations
 *
 * @param {object} form
 * @param {object} vm
 * @param {object} validations
 */
const addValidations = (form, vm, validations) => {
    const validationFields = Object.keys(validations);
    form.fieldKeys = [...form.fieldKeys, validationFields];
    validationFields.forEach((field) => {
        setFieldProps(form, field, validations, vm);
    });
};

/**
 * Submit the form
 *
 * @param {object} form
 * @param {string} route
 * @param {string} method
 * @param {object} data
 * @param {object} vm
 * @param {bool} skipValidate
 */
const submit = (form, route, method, data, vm, skipValidate = false) => {
    form.$submitted = true;
    return new Promise((resolve) => {
        if (skipValidate || form.validate()) {
            form.$submitting = true;
            vm.$clearwaste.$loading.start();
            vm.$axios[method](route, data)
                .then((response) => {
                    resolve(response);
                    form.$submitting = false;
                    vm.$clearwaste.$loading.finish();
                })
                .catch(({ response }) => {
                    const { errors } = response.data;
                    form.$hasError = true;
                    if (typeof errors === 'string') {
                        form.$errorMessage = errors;
                    } else if (errors) {
                        addServerErrors(form, errors);
                    } else if (!response.data.banned) {
                        form.$errorMessage = vm.$t('common.error_try_again');
                    }
                    form.$submitting = false;
                    vm.$clearwaste.$loading.finish();
                });
        }
    });
};

const reset = (form) => {
    form.$errorMessage = null;
    form.$hasError = false;
    form.$submitted = false;
    form.$submitting = false;
    form.fieldKeys.forEach((fieldKey) => {
        form[fieldKey].$errorMessage = null;
        form[fieldKey].$hasError = null;
    });
};

/**
 * Create a reactive model to hold the form
 *
 * @param {object} vm
 * @param {object} validations
 */
const validateModel = (vm, validations) => {
    const Vue = getVue(vm);
    const { VBase } = getComponent(Vue);
    const root = new VBase({
        data() {
            return {
                form: {
                    fieldKeys: [],
                    $errorMessage: null,
                    $hasError: false,
                    $submitted: false,
                    $submitting: false,
                },
            };
        },
        computed: {
            /**
             * Returns the object that becomes $form
             */
            formComp() {
                if (validations) {
                    this.form.fieldKeys = Object.keys(validations);
                    this.form.fieldKeys.forEach((field) => {
                        setFieldProps(this.form, field, validations, vm);
                    });
                    Vue.set(this.form, 'replaceValidations', (moreValidations) => {
                        validations = moreValidations;
                        this.form.fieldKeys = Object.keys(validations);
                        this.form.fieldKeys.forEach((field) => {
                            setFieldProps(this.form, field, moreValidations, vm);
                        });
                    });
                    Vue.set(this.form, 'addValidations', (newValidations) => addValidations(this.form, vm, newValidations));
                    Vue.set(this.form, 'validate', () => validate(this.form, vm, true));
                    Vue.set(this.form, 'validateFields', (fields) => validateFields(this.form, vm, fields));
                    Vue.set(
                        this.form,
                        'submit',
                        (route, method, data = {}, skipValidate = false) => submit(this.form, route, method, data, vm, skipValidate),
                    );
                    Vue.set(this.form, 'reset', () => reset(this.form));
                }
                return this.form;
            },
        },
    });
    return root;
};

/**
 * A mixin containing the $form computed
 */
export default {
    data() {
        const vals = this.$options.validations;
        if (vals) {
            this._form = validateModel(this, vals);
        }
        return {};
    },
    beforeCreate() {
        const options = this.$options;
        const vals = options.validations;
        if (!vals) return;
        if (!options.computed) options.computed = {};
        if (options.computed.$form) return;
        options.computed.$form = function formComp() {
            // Re-init form when page is recreated
            if (!this._form && this.$options.validations) {
                this._form = validateModel(this, this.$options.validations);
            }
            return this._form ? this._form.formComp : null;
        };
    },
    beforeDestroy() {
        if (this._form) {
            this._form.$destroy();
            this._form = null;
        }
    },
};
