function uploadImage(file, endpoint, $axios) {
    const data = new FormData();

    if (typeof file === 'string') {
        data.append('tokens[]', file);
    } else {
        data.append('images', file.data, file.data.name);
    }

    return $axios.$post(endpoint, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
            if (typeof file === 'string') {
                return;
            }
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            file.progress = percentCompleted;
        },
    });
}

function uploadImages(files, $axios, $config, $sentry, instantUpload) {
    const imagePromises = [];
    let endpoint = $config.endpoints.image;
    if (instantUpload) {
        endpoint = $config.endpoints.uploadImage;
    }

    files.forEach((file) => {
        imagePromises.push(new Promise((resolve, reject) => {
            uploadImage(file, endpoint, $axios).then((response) => {
                if (typeof file === 'object') {
                    file.isFinished = true;
                }
                resolve(response);
            }).catch((err) => {
                file.hasError = true;
                if ($sentry && process.env.NODE_ENV === 'production') {
                    $sentry.captureException(err);
                } else {
                    console.error(err);
                }
                reject(err);
            });
        }));
    });

    return imagePromises;
}

function resolveUploads(imagePromises, resolve, reject, single, instantUpload) {
    Promise.all(imagePromises).then((responses) => {
        const images = [];
        responses.forEach((response) => {
            if (response) {
                if (instantUpload) {
                    images.push(response.tokens[0]);
                } else {
                    images.push(response.images[0]);
                }
            }
        });
        if (single) {
            resolve(images[0]);
        } else {
            resolve(images);
        }
    }).catch((e) => {
        reject(e);
    });
}

/**
 * Upload an array of files
 *
 * @param {array} files
 * @param {object} $axios
 * @param {object} $config
 * @param {object} $sentry
 * @param {boolean} loggedIn
 * @param {boolean} single
 */
export default function fileUpload(files, $axios, $config, $sentry, single = false, instantUpload = false) {
    return new Promise((resolve, reject) => {
        const imagePromises = uploadImages(files, $axios, $config, $sentry, instantUpload);
        resolveUploads(imagePromises, resolve, reject, single, instantUpload);
    });
}
