
import { mapState } from 'vuex';
import externalLink from '~/mixins/externalLink';

export default {
    name: 'SiteFooterSocial',
    mixins: [externalLink],
    computed: mapState(['isCordova']),
};

