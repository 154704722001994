export const state = () => ({
    confirm: {
        text: '',
        title: '',
        callback: {},
        show: false,
        confirmButtonText: '',
        confirmButtonType: '',
        rejectButtonText: '',
    },
    flashMessage: {
        type: '',
        message: '',
        title: '',
        active: false,
    },
    fromRoute: null, // Used for back button
    isCordova: typeof window !== 'undefined' && window.location.protocol === 'file:',
    isLanding: true,
    isMobile: true,
    locales: ['en'],
    locale: 'en',
    reportable: {
        show: false,
        endpoint: '',
    },
    showNav: true,
});

export const actions = {
    clearFlashMessage({ commit }) {
        commit('CLEAR_FLASH_MESSAGE');
    },
    setFlashMessage({ commit }, message) {
        commit('SET_FLASH_MESSAGE', message);
        setTimeout(() => {
            commit('CLEAR_FLASH_MESSAGE');
        }, 4000);
    },
    hideConfirm({ commit }) {
        commit('hideConfirm');
    },
    showConfirm({ commit }, confirm) {
        commit('showConfirm', confirm);
    },
    hideNav({ commit }) {
        commit('HIDE_NAV');
    },
    showNav({ commit }) {
        commit('SHOW_NAV');
    },
    showReportable({ commit }, endpoint) {
        commit('SHOW_REPORTABLE', endpoint);
    },
    hideReportable({ commit }) {
        commit('HIDE_REPORTABLE');
    },
};

/* eslint no-shadow: 0 */
export const mutations = {
    CLEAR_FLASH_MESSAGE(state) {
        state.flashMessage.active = false;
    },
    SET_FLASH_MESSAGE(state, { message, type = 'success', title }) {
        state.flashMessage.active = true;
        state.flashMessage.type = type;
        state.flashMessage.message = message;
        state.flashMessage.title = title;
    },
    SET_FROM_ROUTE(state, from) {
        state.fromRoute = from;
    },
    SET_IS_MOBILE(state, value) {
        state.isMobile = value;
    },
    SET_LANDING(state, value) {
        state.isLanding = value > -1;
    },
    SET_LANG(state, locale) {
        if (state.locales.indexOf(locale) !== -1) {
            state.locale = locale;
        }
    },
    HIDE_NAV(state) {
        state.showNav = false;
    },
    SHOW_NAV(state) {
        state.showNav = true;
    },
    HIDE_REPORTABLE(state) {
        state.reportable.show = false;
    },
    SHOW_REPORTABLE(state, endpoint) {
        state.reportable.endpoint = endpoint;
        state.reportable.show = true;
    },
    hideConfirm(state) {
        state.confirm.show = false;
        state.confirm.callback = {};
    },
    showConfirm(state, confirm) {
        state.confirm.text = confirm.text;
        state.confirm.title = confirm.title;
        state.confirm.callback = confirm.callback;
        state.confirm.confirmButtonText = confirm.confirmButtonText;
        state.confirm.confirmButtonType = confirm.confirmButtonType;
        state.confirm.rejectButtonText = confirm.rejectButtonText;
        state.confirm.show = true;
    },
};
