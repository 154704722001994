import isEmail from 'validator/lib/isEmail';

/**
 * Validate an email
 */
export default {
    /**
     * Check the validation
     * @param {string|null} value
     */
    check(value) {
        return isEmail(`${value}`);
    },
    /**
     * Return an error message
     * @param {object} vm
     */
    message(vm) {
        return vm.$t('error_messages.email');
    },
};
