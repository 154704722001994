import { render, staticRenderFns } from "./SiteConfirmation.vue?vue&type=template&id=dc2babdc"
import script from "./SiteConfirmation.vue?vue&type=script&lang=js"
export * from "./SiteConfirmation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconExclamationTriangle: require('/opt/atlassian/pipelines/agent/build/components/icon/IconExclamationTriangle.vue').default,IconInfoCircle: require('/opt/atlassian/pipelines/agent/build/components/icon/IconInfoCircle.vue').default,FormSiteButton: require('/opt/atlassian/pipelines/agent/build/components/form/SiteButton.vue').default,SiteModal: require('/opt/atlassian/pipelines/agent/build/components/SiteModal.vue').default})
