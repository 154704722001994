
export default {
    props: {
        /**
         * @model
         */
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            drag: {
                startX: 0,
                endX: 0,
                startY: 0,
                letItGo: null,
                preventClick: false,
            },
            pointerDown: false,
            transformProperty: 'transform',
        };
    },
    watch: {
        value(val) {
            if (!val) {
                this.$refs.menu.style[this.transformProperty] = 'translate3d(0, 0, 0)';
            }
        },
    },
    mounted() {
        this.transformProperty = this.webkitOrNot();
    },
    methods: {
        webkitOrNot() {
            const { style } = document.documentElement;
            if (typeof style.transform === 'string') {
                return 'transform';
            }
            return 'WebkitTransform';
        },
        clearDrag() {
            this.drag = {
                startX: 0,
                endX: 0,
                startY: 0,
                letItGo: null,
                preventClick: this.drag.preventClick,
            };
        },
        enableTransition() {
            this.$refs.menu.style.webkitTransition = 'all 200ms ease-out';
            this.$refs.menu.style.transition = 'all 200ms ease-out';
        },
        touchstartHandler(e) {
            this.pointerDown = true;
            this.drag.startX = e.touches[0].pageX;
            this.drag.startY = e.touches[0].pageY;
        },
        touchmoveHandler(e) {
            if (this.drag.letItGo === null) {
                this.drag.letItGo = Math.abs(this.drag.startX - e.touches[0].pageX) < Math.abs(this.drag.startY - e.touches[0].pageY);
            }

            if (this.pointerDown && this.drag.letItGo) {
                this.drag.endY = e.touches[0].pageY;
                this.$refs.menu.style.webkitTransition = 'all 0ms ease-out';
                this.$refs.menu.style.transition = 'all 0ms ease-out';

                let dragOffset = this.drag.endY - this.drag.startY;
                if (dragOffset > 150) {
                    dragOffset = 300;
                    setTimeout(() => {
                        this.$emit('input', false);
                    }, 50);
                }
                if (dragOffset > 0) {
                    this.$refs.menu.style[this.transformProperty] = `translate3d(0, ${dragOffset}px, 0)`;
                }
            }
        },
        touchendHandler() {
            this.pointerDown = false;
            this.enableTransition();
            if (this.drag.endY) {
                this.updateAfterDrag();
            }
            this.clearDrag();
        },
        updateAfterDrag() {
            const offset = -1 * this.currentSlide * (this.width / this.perPage);

            // This one is tricky, I know but this is a perfect explanation:
            // https://youtu.be/cCOL7MC4Pl0
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.enableTransition();
                    this.$refs.menu.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`;
                });
            });
        },
    },
};

